import { Component } from '../types';

export const quiz = {
  id: 'abc123',
  default: true,
  title: 'Default Quiz',
  version: 1.0,
  components: [
    {
      type: 'SELECT',
      title: '',
      text: 'Are you new to aesthetic treatments?',
      answerOptions: [
        { text: `Yes, I'm a newbie` },
        { text: `No, I've had some past experience` },
      ],
      required: true,
      order: 1,
      componentKey: 'newToTreatments',
    },
    {
      type: 'SELECT',
      title: 'Your age',
      text: 'How old are you?',
      answerOptions: [
        { text: 'Under 18' },
        { text: '19-24' },
        { text: '25-34' },
        { text: '35-44' },
        { text: '45-54' },
        { text: '55-64' },
        { text: '65+' },
      ],
      required: true,
      order: 2,
      componentKey: 'ageGroup',
    },
    {
      type: 'SELECT',
      title: 'Your gender',
      text: 'What is your gender?',
      answerOptions: [
        { text: 'Female' },
        { text: 'Male' },
        { text: 'Non-Binary' },
        { text: `I'd rather not say` },
      ],
      disclaimer: 'This helps us determine results more accurately',
      required: true,
      order: 3,
      componentKey: 'gender',
    },
    {
      type: 'SELECT',
      title: 'Areas',
      text: 'Are you looking for face or body treatments?',
      answerOptions: [{ id: '', text: 'Face' }, { text: 'Body' }],
      required: true,
      order: 4,
      componentKey: 'treatmentAreas',
    },
    {
      type: 'CONCERNS',
      title: 'Your concerns',
      text: 'What are your \n top 3 concerns?',
      answerOptions: [
        { text: 'Acne' },
        { text: 'Dark Spots / Hyperpigmentation' },
        { text: 'Deep lines & wrinkles' },
        { text: 'Fine lines' },
        { text: 'Acne2' },
        { text: 'Dark Spots / Hyperpigmentation2' },
        { text: 'Deep lines & wrinkles2' },
        { text: 'Fine lines2' },
        { text: 'Acne3' },
        { text: 'Dark Spots / Hyperpigmentation3' },
        { text: 'Deep lines & wrinkles3' },
        { text: 'Fine lines3' },
      ],
      required: true,
      order: 5,
      componentKey: 'concerns',
    },
    {
      type: 'SELECT',
      title: 'Desired downtime',
      text: 'How much downtime after treatment can you allow?',
      answerOptions: [
        { text: 'None, I have a life to live!' },
        { text: 'A day or two' },
        { text: 'Whatever it takes' },
      ],
      required: true,
      order: 6,
      componentKey: 'downtime',
    },
    {
      type: 'SELECT',
      title: 'Your skintone',
      text: `What's your usual skin tone without a tan?`,
      answerOptions: [{ text: 'Light' }, { text: 'Dark' }],
      required: true,
      order: 7,
      componentKey: 'skintone',
    },
    {
      type: 'PAGE',
      title: 'More about you',
      text: `Next, we’ll ask you some questions that help us fine-tune your results`,
      disclaimer:
        '(Tip: Answering these questions helps us make the best recommendations possible)',
      buttonText: `Let's go`,
      order: 8,
      componentKey: 'fineTunePage',
    },
    {
      type: 'SELECT',
      title: '',
      text: `What's your ethnic background?`,
      answerOptions: [
        { text: 'African American / Black' },
        { text: 'Asian' },
        { text: 'Hispanic / Latino' },
        { text: 'Middle Eastern / North African' },
        { text: 'Native American / Alaska Native' },
        { text: 'Native Hawaiian / Pacific Islander' },
        { text: 'White / Caucasian' },
        { text: 'Mixed Race / Multi-racial' },
        { text: 'Prefer Not to Say' },
      ],
      required: true,
      order: 9,
      componentKey: 'ethnicity',
    },
    {
      type: 'SELECT',
      title: 'Timeframe',
      text: 'How soon are you looking to make a change?',
      answerOptions: [
        { text: 'ASAP!' },
        { text: 'Soon, but when the time is right' },
        { text: `I don't know yet` },
      ],
      required: true,
      order: 10,
      componentKey: 'timeToBook',
    },
  ] as Component[],
};

import React, { useState, useRef, useEffect } from 'react';
import { FullStory } from '@fullstory/browser';
import logUserAction from '@/log/logUserAction';
import Button from '@/components/Button/Button';
import Input from '@/components/Input/Input';
import DropdownArrow from '@/media/dropdown-arrow.svg';
import { useUserStore, requirePhoneNumber } from '@/stores/useUserStore';
import { useProviderStore } from '@/stores/useProviderStore';
import {
  useGetProviderLocationsQuery,
  useUpdateProviderUserMutation,
} from '@/graphql/generated/graphql';

// Suppress no-autofocus error given lack of accessibility risk
// autofocus is used specifically to force selection of the first input control on each page
/* eslint-disable jsx-a11y/no-autofocus */

// Profile completion page, requires at least first name to progress
// Page is expected to be expected by updating the user profile, triggering completion of login
const CompleteProfilePage: React.FC = () => {
  const [showFirstnameMissing, setShowFirstnameMissing] = useState(false);
  const [showPhoneMissing, setShowPhoneMissing] = useState(false);
  const [showLocationMissing, setShowLocationMissing] = useState(false);
  const [isPhoneInputAccessed, setIsPhoneInputAccessed] = useState(false);

  const currentUser = useUserStore(state => state.user);
  const updateUser = useUserStore(state => state.updateUser);

  const firstnameInput = useRef<HTMLInputElement>(null);
  const lastnameInput = useRef<HTMLInputElement>(null);
  const phoneInput = useRef<HTMLInputElement>(null);
  const locationSelect = useRef<HTMLSelectElement>(null);
  const submitButton = useRef<HTMLButtonElement>(null);

  const { provider } = useProviderStore();
  const getProviderLocationsResult = useGetProviderLocationsQuery({
    variables: { providerId: provider!.id },
  });
  const [updateProviderUserMutation] = useUpdateProviderUserMutation();
  const [isExistingClient, setIsExistingClient] = useState(false);
  const [clientLocation, setClientLocation] = useState('');

  useEffect(() => {
    FullStory('trackEvent', {
      name: `LogIn/CompleteProfilePage`,
      properties: {},
    });
    logUserAction('> CompleteProfilePage');
  }, []);

  useEffect(() => {
    if (submitButton.current) {
      submitButton.current.scrollIntoView();
    }
  }, [isExistingClient]);

  if (currentUser === null) {
    // Expect currentUser populated with at least email address before this page is reached
    throw new Error('Attempting profile update before User hydrated');
  }

  // Populate data on mount if some exists for sake of graceful handling, but should not happen
  // (page should only be shown if required data is not already hydrated)
  useEffect(() => {
    if (currentUser.firstname) firstnameInput.current!.value = currentUser.firstname;
    if (currentUser.lastname) lastnameInput.current!.value = currentUser.lastname;
    if (currentUser.phone)
      phoneInput.current!.value = toPhoneNumber(currentUser.phone.slice(-10));
  }, [currentUser]);

  // Utility functions
  const capitalize = (str: string) => str && str[0].toUpperCase() + str.slice(1);
  const toNumeric = (str: string) => str.replaceAll(/[^0-9]/g, '');
  const toPhoneNumber = (
    str: string // assume US 10-digit
  ) =>
    str.length
      ? `( ${str.slice(0, 3)}` +
        (str.length >= 3
          ? ` ) ${str.slice(3, 6)}` + (str.length >= 6 ? ` - ${str.slice(6, 10)}` : '')
          : '')
      : '';
  const isValidPhoneNumber = (str: string) => str.length === 18; // matched to format above

  // First name must be capitalized, letters only, Enter moves to last name if at least one character
  const handleFirstnameKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (firstnameInput.current!.value && event.key === 'Enter') {
      lastnameInput.current!.focus();
    }
  };

  const handleFirstnameInput = () => {
    firstnameInput.current!.value = capitalize(
      firstnameInput.current!.value.replace(/[^a-zA-Z]/, '')
    );

    setShowFirstnameMissing(false);
  };

  // Last name must be capitalized, letters only, Enter moves to phone #
  const handleLastnameKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      phoneInput.current!.focus();
    }
  };

  const handleLastnameInput = () =>
    (lastnameInput.current!.value = capitalize(
      lastnameInput.current!.value.replace(/[^a-zA-Z]/, '')
    ));

  // Phone number must be numeric, will display as (...) ...-....
  const handlePhoneInput = () => {
    const numericString = toNumeric(phoneInput.current!.value);
    phoneInput.current!.value = numericString ? toPhoneNumber(numericString) : '';

    setShowPhoneMissing(false);
  };

  const handlePhoneKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace') {
      const numericString = toNumeric(phoneInput.current!.value);
      phoneInput.current!.value = numericString
        ? toPhoneNumber(numericString.slice(0, numericString.length - 1))
        : '';

      event.preventDefault();
    }
  };

  const handlePhoneInputFocus = () => {
    if (!isPhoneInputAccessed) {
      setIsPhoneInputAccessed(true);
      FullStory('trackEvent', {
        name: 'Complete Profile',
        properties: {
          phoneInputFocus: true,
        },
      });
    }
  };

  const handleLocationSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setShowLocationMissing(false);
    setClientLocation(event.target.value);
  };

  const handleCheckValidity = () => {
    let validityError = false;

    if (!firstnameInput.current!.value) {
      validityError = true;
      setShowFirstnameMissing(true);
      firstnameInput.current!.focus();
    }
    if (requirePhoneNumber && !isValidPhoneNumber(phoneInput.current!.value)) {
      setShowPhoneMissing(true);
      if (!validityError) {
        phoneInput.current!.focus();
        validityError = true;
      }
    }
    if (isExistingClient && !clientLocation) {
      setShowLocationMissing(true);
      if (!validityError) {
        locationSelect.current!.focus();
        validityError = true;
      }
    }

    if (validityError) return;

    logUserAction('Profile completed');

    updateUser({
      ...currentUser,
      firstname: firstnameInput.current!.value,
      lastname: lastnameInput.current!.value,
      phone:
        phoneInput.current!.value &&
        `+1${phoneInput.current!.value.replaceAll(/[^0-9]/g, '')}`,
    });

    updateProviderUserMutation({
      variables: {
        visitedLocations: [clientLocation],
      },
    });
  };

  return (
    <>
      <h2 className="text-center">Complete my profile:</h2>
      <div>
        <div className="mt-10 mb-4">
          <Input
            inputName="fname"
            inputRef={firstnameInput}
            withLabel
            labelText="First name (required):"
            labelAlign="left"
            autoComplete="given-name"
            handleOnKeyDown={handleFirstnameKeyDown}
            handleOnInput={handleFirstnameInput}
            autoFocus
          />
          {showFirstnameMissing && (
            <p className="text-center text-sm">Please enter a first name</p>
          )}
        </div>
        <div className="my-4">
          <Input
            inputName="lname"
            inputRef={lastnameInput}
            withLabel
            labelText="Last name:"
            labelAlign="left"
            autoComplete="family-name"
            handleOnKeyDown={handleLastnameKeyDown}
            handleOnInput={handleLastnameInput}
          />
        </div>
        <div className="my-4">
          <Input
            inputName="phone"
            inputType="PHONE"
            inputRef={phoneInput}
            withLabel
            labelText={`Phone number${requirePhoneNumber ? ' (required)' : ''}:`}
            labelAlign="left"
            autoComplete="tel-national"
            inputPlaceholder="Please enter your phone #"
            noShrink
            handleOnKeyDown={handlePhoneKeyDown}
            handleOnInput={handlePhoneInput}
            handleOnFocus={handlePhoneInputFocus}
          />
          {showPhoneMissing && (
            <p className="text-center text-sm">Please enter a valid US phone number</p>
          )}
        </div>
        <fieldset className={`mt-4 ${isExistingClient ? 'mb-4' : 'mb-12'} accent-black`}>
          <legend className="block text-left mb-2">Are you a current client?</legend>
          <div className="flex justify-between">
            <span className="flex w-28 px-4 py-3 border border-gray-300 focus-within:border-black focus-within:bg-[#eee9e3] rounded-lg justify-left items-center">
              <input
                type="radio"
                className="focus:outline-none"
                id="existingClient"
                checked={isExistingClient}
                onChange={() => setIsExistingClient(true)}
                tabIndex={isExistingClient ? 0 : -1}
              />
              <label htmlFor="existingClient" className="w-full pl-3 text-left">
                Yes
              </label>
            </span>
            <span className="flex w-28 px-4 py-3 border border-gray-300 focus-within:border-black focus-within:bg-[#eee9e3] rounded-lg justify-left items-center">
              <input
                type="radio"
                className="focus:outline-none"
                id="newClient"
                checked={!isExistingClient}
                onChange={() => setIsExistingClient(false)}
                tabIndex={isExistingClient ? -1 : 0}
              />
              <label htmlFor="newClient" className="w-full pl-3 text-left">
                No
              </label>
            </span>
          </div>
        </fieldset>
        {isExistingClient && (
          <div className="mt-4 mb-12">
            <label htmlFor="location" className="block mb-2 text-left">
              At which location?
            </label>
            <span className="flex w-60 px-3 border border-gray-300 focus-within:border-gray-500 rounded-lg">
              <select
                id="location"
                ref={locationSelect}
                className={`appearance-none w-full py-3 pr-4 font-assistant text-center ${[
                  clientLocation ? 'text-black' : 'text-gray-300',
                  clientLocation.length > 15 ? 'text-md' : 'text-lg',
                ].join(' ')} bg-white bg-right bg-no-repeat focus:outline-none`}
                onChange={handleLocationSelectChange}
                style={{ backgroundImage: `url(${DropdownArrow})` }}
              >
                <option value="" selected={!clientLocation} hidden disabled>
                  Select a location
                </option>
                {getProviderLocationsResult.data &&
                  getProviderLocationsResult.data.providerLocations.map(location => (
                    <option
                      value={location.name}
                      key={location.name}
                      selected={clientLocation === location.name}
                    >
                      {location.name}
                    </option>
                  ))}
              </select>
            </span>
            {showLocationMissing && (
              <p className="text-center text-sm">Please select your location here ^</p>
            )}
          </div>
        )}
      </div>
      <Button buttonRef={submitButton} withIcon shrink onClick={handleCheckValidity}>
        Get my results
      </Button>
    </>
  );
};

export default CompleteProfilePage;

import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { usePhotoStore } from '@/stores/usePhotoStore';
import { useHeaderStore } from '@/stores/useHeaderStore';
import ChooseOption from './ChooseOption/ChooseOption';
import TakePhoto from './TakePhoto/TakePhoto';
import PreviewPhoto from './PreviewPhoto/PreviewPhoto';
import Container from '@/components/Container/Container';
import { useFooterStore } from '@/stores/useFooterStore';

const TakeAPhoto: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentStep = usePhotoStore(state => state.currentStep);
  const setLeftButton = useHeaderStore(state => state.setLeftButton);
  const setOnLeftButtonClick = useHeaderStore(state => state.setOnLeftButtonClick);
  const setTitle = useHeaderStore(state => state.setTitle);
  const setTitleLevel = useHeaderStore(state => state.setTitleLevel);
  const { setButtonText } = useFooterStore();

  useEffect(() => {
    setTitleLevel(2);
    setTitle('');
    setLeftButton('Back');
    setOnLeftButtonClick(() => usePhotoStore.getState().setStep('CHOOSE_OPTION'));
    setButtonText('');

    if (currentStep === 'CHOOSE_OPTION')
      setOnLeftButtonClick(() => navigate('/quiz/questions'));
  }, [
    currentStep,
    setTitle,
    setTitleLevel,
    navigate,
    setLeftButton,
    setOnLeftButtonClick,
    setButtonText,
  ]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (currentStep === 'TAKE_PHOTO') {
      searchParams.set('camera', 'active');
      navigate({ search: searchParams.toString() }, { replace: true });
    } else if (location.search.includes('camera=active')) {
      searchParams.delete('camera');
      navigate({ search: searchParams.toString() }, { replace: true });
    }
  }, [currentStep, navigate, location.search]);

  return (
    <Container centerVertical>
      {currentStep === 'CHOOSE_OPTION' && <ChooseOption />}
      {currentStep === 'TAKE_PHOTO' && <TakePhoto />}
      {currentStep === 'PREVIEW_PHOTO' && <PreviewPhoto />}
    </Container>
  );
};

export default TakeAPhoto;

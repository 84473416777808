import React from 'react';

interface ContainerProps {
  children: React.ReactNode;
  centerVertical?: boolean;
  centerHorizontal?: boolean;
}

const Container: React.FC<ContainerProps> = ({
  children,
  centerVertical,
  centerHorizontal = true,
}) => {
  const classes = 'flex flex-col h-full text-center ';
  const propClasses = [
    centerVertical ? 'justify-center' : 'justify-start',
    centerHorizontal ? 'items-center' : 'items-start',
  ].join(' ');

  return <div className={classes + propClasses}>{children}</div>;
};

export default Container;

import React, { useState, useEffect } from 'react';
import { FullStory } from '@fullstory/browser';
import logUserAction from '@/log/logUserAction';
import Input from '@/components/Input/Input';
import SocialLogin from '@/components/SocialLogin/SocialLogin';
import { logIn } from '@/stores/useUserStore';
import { useVerifyEmailCodeMutation } from '@/graphql/generated/graphql';
import { LogInPageStateEnum } from './LogIn';

// Suppress no-autofocus error given lack of accessibility risk
// autofocus is used specifically to force selection of the first input control on each page
/* eslint-disable jsx-a11y/no-autofocus */

interface EnterCodePageProps {
  setLogInState: (newState: LogInPageStateEnum) => void;
  emailToVerify: string;
}

// Email verification code page, with social buttons as alternative
const EnterCodePage: React.FC<EnterCodePageProps> = ({
  setLogInState,
  emailToVerify,
}) => {
  const CODE_LENGTH = 4;
  const [showValidityError, setShowValidityError] = useState(false);
  const [verifyEmailCodeMutation] = useVerifyEmailCodeMutation();

  useEffect(() => {
    FullStory('trackEvent', {
      name: `LogIn/EnterCodePage`,
      properties: {},
    });
    logUserAction('> EnterCodePage');
  }, []);

  const handleCodeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    if (value.length === CODE_LENGTH) {
      verifyEmailCodeMutation({
        variables: {
          email: emailToVerify,
          code: value,
        },
      })
        .then(({ data }) => {
          const accessToken = data && data.authVerifyEmailCode.accessToken;
          if (!accessToken) throw new Error('Missing access token'); // Currently should not occur

          logIn(accessToken); // On success user will be populated & trigger parent re-render
        })
        .catch(() => {
          // Currently failed verification throws 500 error along with actual server errors
          setShowValidityError(true);
        });
    } else {
      setShowValidityError(false);
    }
  };

  const handleRetryLink = () => {
    setLogInState('LOGGED_OUT');
  };

  return (
    <>
      <h2 className="text-center">
        Please check your
        <br />
        email for a code
      </h2>
      <p className="text-center my-3 text-sm">
        We&apos;ve sent a 4-digit code to
        <br />
        <b>{emailToVerify}</b>
      </p>
      <div className="mb-4">
        <Input
          inputType="CODE"
          maxLength={CODE_LENGTH}
          handleOnInput={handleCodeInput}
          autoFocus
        />
      </div>
      {showValidityError && (
        <p className="mb-8 text-center text-sm">
          That code wasn&apos;t correct
          <br />
          Please check and try again!
        </p>
      )}
      <p className="text-center text-sm">
        Can&apos;t find your code?
        <br />
        Check your spam folder
      </p>
      <p className="mt-4 text-center text-sm">
        Code expired or need to try a<br />
        different email address?
      </p>
      {/* TODO: Semantically correct element is a button w/ text styling */}
      {/* eslint-disable-next-line */}
      <p className="text-center text-sm" onClick={handleRetryLink}>
        <b>
          <u>Click here</u>
        </b>
      </p>
      <p className="text-center mt-8 mb-12 text-sm font-bold">or</p>
      <SocialLogin />
    </>
  );
};

export default EnterCodePage;

const LAMBDA_URL_LOGUSERACTION =
  // 'https://tmhstfo2bo4fsmjfrjnxjclchu0vrudn.lambda-url.us-east-2.on.aws';
  'https://ok7ogqu6l4jujsblwsnbkorcfa0qscpj.lambda-url.us-east-1.on.aws'; // --> Prod lambda URL to use when DB bastion setup.

const logUserAction = (action: string) => {
  let sessionId = sessionStorage.getItem('opaline-sessionid');
  if (!sessionId) {
    sessionId = window.crypto.randomUUID();
    sessionStorage.setItem('opaline-sessionid', sessionId);
  }

  try {
    fetch(LAMBDA_URL_LOGUSERACTION, {
      method: 'POST',
      body: JSON.stringify({ sessionId, action }),
    });
  } catch (error) {
    console.log(error);
  }
};

export default logUserAction;

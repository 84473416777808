import React from 'react';
import { usePhotoStore } from '@/stores/usePhotoStore';
import CameraIcon from '@/media/camera.svg';
import { useNavigate } from 'react-router-dom';

const ChooseOption: React.FC = () => {
  const navigate = useNavigate();
  const setStep = usePhotoStore(state => state.setStep);

  return (
    <div className="flex justify-center items-center h-full">
      <div>
        <h2>Let’s take a photo</h2>
        <div className="mt-8">
          <button
            className="p-4 border-0 border-gray-300 rounded-full flex flex-col items-center"
            onClick={() => setStep('TAKE_PHOTO')}
          >
            <img src={CameraIcon} alt="Camera" className="text-center" />
            <p className="mt-2 text-center">Tap to take a photo now</p>
          </button>
          {/* TODO: Re-enable when we do user photos */}
          {/* <p className="mt-4">or</p>
          <button
            className="text-purple-600 text-xl font-thin mt-8"
            onClick={() => console.log('Handle upload')}
          >
            upload your own photo
          </button> */}
        </div>
        <p className="mt-20 text-sm text text-gray-500" style={{ fontSize: '17px' }}>
          Feeling shy?{' '}
          <button
            className="text-purple-600"
            onClick={() => navigate('/quiz/get-my-results')}
          >
            Skip this step
          </button>
        </p>
      </div>
    </div>
  );
};

export default ChooseOption;

import React from 'react';
import { useQuestion } from './useQuestion';
import { Question } from '../types';

interface MultiSelectQuestionProps {
  question: Question;
}

const MultiSelectQuestion: React.FC<MultiSelectQuestionProps> = ({ question }) => {
  const { selectedAnswers, handleAnswerChange } = useQuestion(question);

  const handleChange = (answer: string) => {
    let updatedAnswers = [...selectedAnswers];
    if (updatedAnswers.includes(answer)) {
      updatedAnswers = updatedAnswers.filter(selected => selected !== answer);
    } else {
      updatedAnswers.push(answer);
    }
    handleAnswerChange(updatedAnswers);
  };

  return (
    <div className="flex flex-col items-center space-y-8">
      <h2 className="text-2xl font-bold mb-8">{question.text}</h2>
      <div className="flex flex-col items-start space-y-4">
        {question?.answerOptions?.map((option, index) => (
          <label key={index} className="inline-flex items-center">
            <input
              type="checkbox"
              checked={selectedAnswers.includes(option.text)}
              onChange={() => handleChange(option.text)}
              className="form-checkbox"
            />
            <span className="ml-2">{option.text}</span>
          </label>
        ))}
      </div>
      {question.disclaimer && (
        <p className="pt-4 font-light text-center text-sm">{question.disclaimer}</p>
      )}
    </div>
  );
};

export default MultiSelectQuestion;

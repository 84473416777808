import React from 'react';
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { ApolloProvider as Provider } from '@apollo/client/react';
import { getBackendUri } from '@/utils/urls';
import authLink from '@/auth/authlink';

// Create the HttpLink
const httpLink = new HttpLink({
  uri: getBackendUri(),
  credentials: 'include',
});

// Combine the links
const link = ApolloLink.from([authLink, httpLink]);

// Create the Apollo Client
export const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

// ApolloProvider component
const ApolloProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Provider client={apolloClient}>{children}</Provider>
);

export default ApolloProvider;

import React, { useState, useEffect } from 'react';

interface LoaderProps {
  handleDoneLoading: () => void;
  speed?: number;
}

const QuizResultLoader: React.FC<LoaderProps> = ({ handleDoneLoading, speed }) => {
  const [progress, setProgress] = useState(0);

  // Simulate loading progress
  useEffect(() => {
    const interval = setInterval(
      () => {
        setProgress(prev => {
          if (prev >= 100) {
            clearInterval(interval);
            handleDoneLoading();
            return 100;
          }
          return prev + 2; // Increases by 2 every interval
        });
      },
      speed ? speed : 250
    );

    return () => clearInterval(interval);
  }, [handleDoneLoading, speed]);

  return (
    <div className="min-h-screen fixed bottom-0 top-0 left-0 right-0 flex flex-col justify-center items-center bg-gray-100 p-4 z-50">
      <h2 className="mb-32 text-center">
        Now creating your
        <br />
        skin analysis
      </h2>

      <div className="w-full max-w-lg bg-gray-300 rounded-full h-14">
        <div
          className="bg-black h-14 rounded-full flex"
          style={{ width: progress < 25 ? '25%' : `${progress}%` }}
        >
          {progress > 15 && (
            <span className="text-white text-center w-full content-center">
              {progress}%
            </span>
          )}
        </div>
      </div>

      <p className="text-sm mt-28 font-assistant">Hold Tight</p>
      <p className="text-center mt-2 text-xl">
        We are generating your results using a combination of your answers.
      </p>
    </div>
  );
};

export default QuizResultLoader;

import React, { useEffect, useRef } from 'react';
import { GoogleLogin, CredentialResponse as GoogleResponse } from '@react-oauth/google';
import pkceChallenge from 'pkce-challenge';
import logUserAction from '@/log/logUserAction';
import FacebookLogin, {
  FacebookResponse,
} from '@/components/FacebookLogin/FacebookLogin';
import { logIn } from '@/stores/useUserStore';
import GoogleLogo from '@/media/google.svg';
import FacebookLogo from '@/media/facebook.svg';
import {
  useVerifyGoogleCredentialMutation,
  useVerifyFacebookCredentialMutation,
  VerifyGoogleCredentialMutationFn,
  VerifyFacebookCredentialMutationFn,
} from '@/graphql/generated/graphql';

export const GOOGLE_CLIENT_ID =
  '1042320394728-4o9je8gq3s7vkklv7pg0km08jul1lp6t.apps.googleusercontent.com';
export const META_CLIENT_ID = '892412482282467';
export const USE_CUSTOM_BUTTONS_WITH_PKCE = true;

type ProviderCredential = {
  provider: string;
  credential: string;
};

const SocialLogin: React.FC = () => {
  // Setup channel to receive any credential passed by an OAuth popup
  // Use BroadcastChannel because popup window handle will be lost when it becomes cross-origin
  const popupHandlerRef = useRef<{
    [provider: string]: (providerCredential: ProviderCredential) => void;
  }>({});
  const popupListenerRef = useRef(new BroadcastChannel('authCredential'));
  popupListenerRef.current.onmessage = message => {
    for (const handler of Object.values(popupHandlerRef.current)) handler(message.data);
  };

  // PKCE "custom" button flow
  const startOAuthLogin = (
    provider: string,
    popupOptions: { width: number; height: number },
    api: string,
    params: { client_id: string; scope: string; prompt?: string },
    verifyMutation: VerifyGoogleCredentialMutationFn | VerifyFacebookCredentialMutationFn
  ) => {
    // Open popup immediately (before URL is available) to avoid browser popup blockers
    const popupName = `OpalineAuth${provider}SignInPopup`; // Cannot reuse popup across providers (optimized for different window sizes)
    const popup = window.open(
      '',
      popupName,
      `popup=true, width=${popupOptions.width}, height=${popupOptions.height}, left=${(window.screen.width - popupOptions.width) / 2}, top=${(window.screen.height - popupOptions.height) / 2}`
    );
    if (!popup) {
      console.log('Failed to open authentication popup');
      return;
    }

    // Trigger API call with PKCE code challenge, async because uses crypto.subtle.digest
    let codeVerifier = '';
    pkceChallenge().then(pkce => {
      codeVerifier = pkce.code_verifier;

      // Use window.open vs. location.assign in case the popup was already open & is now cross-origin
      window.open(
        `${api}?` +
          new URLSearchParams({
            ...params,
            redirect_uri: `https://${window.location.host}/authredirect`,
            response_type: 'code',
            code_challenge: pkce.code_challenge,
            code_challenge_method: 'S256',
            state: provider,
          }),
        popupName
      );
    });

    // Register listener callback to receive credential
    // Once popup returns from API request issue backend mutation to verify & read user identity
    const targetHost = new URL(api).hostname;

    return new Promise<string>(() => {
      popupHandlerRef.current[targetHost] = (receivedCredential: ProviderCredential) => {
        if (receivedCredential && receivedCredential.provider === provider) {
          popupHandlerRef.current = {};

          verifyMutation({
            variables: { credential: receivedCredential.credential, codeVerifier },
          });
        }
      };
    });
  };

  // Google custom button PKCE flow click handler
  const loginWithGoogleCustom = () => {
    logUserAction('LOGIN_USING_GOOGLE');

    startOAuthLogin(
      'Google',
      { width: 500, height: 550 },
      'https://accounts.google.com/o/oauth2/v2/auth',
      {
        client_id: GOOGLE_CLIENT_ID,
        scope:
          'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
        prompt: 'select_account', // Omitting this will skip prompt if user has logged in here via Google before
      },
      verifyGoogleMutation
    );
  };

  // Google personalized button flow (Sign in with Google) callback from SDK
  const loginWithGoogle = (response: GoogleResponse) => {
    logUserAction('LOGIN_USING_GOOGLE');

    if (!response!.credential) return;

    // Verify returned credential through backend
    verifyGoogleMutation({ variables: { credential: response!.credential } });
  };

  // Google backend token verification
  const [verifyGoogleMutation, verifyGoogleResults] = useVerifyGoogleCredentialMutation({
    errorPolicy: 'all',
    onCompleted: data => {
      const { accessToken } = data.authVerifyGoogleCredential;
      logIn(accessToken);
    },
    onError: error => {
      // May need to handle "email address not verified by Google"
      console.log(error);
      verifyGoogleResults.reset();
    },
  });

  // Facebook custom button PKCE flow click handler
  const loginWithFacebookCustom = () => {
    logUserAction('LOGIN_USING_FB');

    startOAuthLogin(
      'Facebook',
      { width: 600, height: 680 },
      'https://www.facebook.com/v11.0/dialog/oauth',
      {
        client_id: META_CLIENT_ID,
        scope: 'public_profile, email',
      },
      verifyFacebookMutation
    );
  };

  // Facebook personalized button flow (Facebook Login Button) callback from SDK
  const loginWithFacebook = (response: FacebookResponse) => {
    logUserAction('LOGIN_USING_FB');

    const credential = response.authResponse.accessToken;
    if (!credential || !response.data) return;

    verifyFacebookMutation({
      variables: {
        credential,
        email: response.data.email,
        firstName: response.data.first_name,
        lastName: response.data.last_name,
      },
    });
  };

  // Facebook backend token verification
  const [verifyFacebookMutation, verifyFacebookResults] =
    useVerifyFacebookCredentialMutation({
      errorPolicy: 'all',
      onCompleted: data => {
        const { accessToken } = data.authVerifyFacebookCredential;
        logIn(accessToken);
      },
      onError: error => {
        console.log(error);
        verifyFacebookResults.reset();
      },
    });

  return (
    <div className="space-y-3 text-sm flex flex-col items-center">
      {USE_CUSTOM_BUTTONS_WITH_PKCE ? (
        <>
          {/* Google custom button w/ PKCE */}
          <button
            className="w-52 bg-white border border-gray-300 py-3 rounded-lg flex justify-center items-center space-x-3"
            onClick={() => loginWithGoogleCustom()}
          >
            <img src={GoogleLogo} alt="" />
            <span className="pt-0.5">Continue with Google</span>
          </button>
          {/* Facebook custom button w/ PKCE */}
          <button
            className="w-52 bg-white border border-gray-300 py-3 rounded-lg flex justify-center items-center space-x-3"
            onClick={() => loginWithFacebookCustom()}
          >
            <img src={FacebookLogo} alt="" />
            <span className="pt-0.5">Continue with Facebook</span>
          </button>
        </>
      ) : (
        <>
          {/* Sign in With Google SDK */}
          <GoogleLogin
            onSuccess={loginWithGoogle}
            onError={() => console.log('Login failed')}
            text="continue_with"
            width="260"
          />
          {/* Facebook Login Button SDK */}
          <FacebookLogin onAttempt={loginWithFacebook} />
        </>
      )}
      {/*
      <button className="bg-black text-white py-3 rounded-lg flex justify-center items-center space-x-2" style={{ width: '260px', height: '40px' }}>
        <span>Continue with Apple</span>
      </button>
      */}
    </div>
  );
};

export default SocialLogin;

// Auth redirect window handler (component used by popup redirect route)
// Extract access token on success & pass to parent window
export const AuthResponseCapture: React.FC = () => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    new BroadcastChannel('authCredential').postMessage({
      provider: searchParams.get('state'),
      credential: searchParams.get('code'),
    });

    // Close popup after delay to allow parent to transition screen
    setInterval(() => window.close(), 500);
  }, []);

  return null;
};

export const getURL = () => new URL(window.location.origin);

export const getBackendUri = () => {
  const hostname = getURL().hostname;
  if (hostname.includes('localhost')) return 'https://localhost:3000/graphql';
  return 'https://backend.opaline.ai/graphql';
};

export const getSubdomain = () => {
  const hostname = getURL().hostname;
  const parts = hostname.split('.');
  if (parts.length > 2) return parts[0];
  return '';
};

export const getDemoOrigin = () => {
  const hostname = getURL().hostname;
  const parts = hostname.split('.');

  // TODO: Going to need something more sophisticated for stage when we get that up and running.
  if (parts.length > 2 || parts.includes('localhost')) parts[0] = 'demo';
  else parts.unshift('demo');
  const demoHostname = parts.join('.');
  return getURL().origin.replace(hostname, demoHostname);
};

import React from 'react';
import Blurb from '@/components/Blurb/Blurb';
import ConcernPills from '@/components/ConcernPills/ConcernPills';
import { Tag } from '@/graphql/generated/graphql';
import { useProviderStore } from '@/stores/useProviderStore';

interface TreatmentBlockProps {
  title: string;
  concerns: Tag[];
  selectedConcerns: Tag[];
  description: string;
  onClick: () => void;
  onlySelectedConcerns?: boolean;
  startingPrice?: number | null;
}

const TreatmentBlock: React.FC<TreatmentBlockProps> = ({
  title,
  concerns,
  selectedConcerns,
  description,
  onClick,
  onlySelectedConcerns,
  startingPrice,
}) => {
  const { settings } = useProviderStore();

  return (
    <div className="max-w-md mx-auto bg-white border border-black rounded-lg shadow-lg mb-7">
      {/* Title Section */}
      <div
        className="bg-black text-white py-4 px-6 rounded-t-lg"
        onClick={onClick}
        aria-hidden="true"
      >
        <h2 className="text-center">{title}</h2>
      </div>

      {/* Body section */}
      <div className="py-2 px-4 text-center">
        <p className="text-xs mb-2">matched your concerns:</p>
        <ConcernPills
          concerns={concerns}
          selectedConcerns={selectedConcerns}
          onlySelectedConcerns={onlySelectedConcerns}
        />
        <Blurb text={description} onClick={onClick} />
        {startingPrice && (
          <p className="pb-3 font-normal">Pricing starts at ${startingPrice}</p>
        )}
        <button
          className="bg-black text-white text-xs font-semibold rounded-full py-2 px-6 mb-2"
          onClick={() => window.open(settings!.links['Book'], '_blank')}
        >
          BOOK NOW
        </button>
      </div>
    </div>
  );
};

export default TreatmentBlock;

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@/components/Button/Button';
import { useProviderStore } from '@/stores/useProviderStore';
import {
  useDecodeLinkTokenQuery,
  useUpdateUnsubscribeByTokenMutation,
} from '@/graphql/generated/graphql';

const Unsubscribe: React.FC = () => {
  const params = useParams();
  let token = params.token;
  let email: string | undefined;
  if (!token) {
    // TODO - remove handling of search params after a month or so, here & in the routes table
    const searchParams = new URLSearchParams(window.location.search);
    token = searchParams.get('token')!;
    email = searchParams.get('email')!;
  }

  const providerName = useProviderStore(state => state.provider && state.provider.name);
  const navigate = useNavigate();

  const { data } = useDecodeLinkTokenQuery({ variables: { token: token! } });
  const [isUnsubscribed, setIsUnsubscribed] = useState(true);
  const [updateUnsubscribeByTokenMutation] = useUpdateUnsubscribeByTokenMutation();

  if (data && !email) email = data.decodeLinkToken.email!;

  useEffect(() => {
    if (email) {
      updateUnsubscribeByTokenMutation({
        variables: {
          token: token!,
          email,
          unsubscribe: true,
        },
      });
    }
  }, [email, token, data, updateUnsubscribeByTokenMutation]);

  // TODO - proper error handling once the page is available
  if (!token) return null;

  const handleResubscribe = () => {
    // Resubscribe
    updateUnsubscribeByTokenMutation({
      variables: {
        token: token!,
        email: email!,
        unsubscribe: false,
      },
    });
    setIsUnsubscribed(false);
  };

  /* eslint-disable react/jsx-no-target-blank */ // Target is our own domain
  return (
    <div className="h-full w-sm flex flex-col bg-white p-14 items-center">
      {isUnsubscribed ? (
        <>
          <h2 className="text-center">You&apos;ve been unsubscribed.</h2>
          <p className="my-10 py-1 text-center text-sm">
            You will no longer receive marketing emails from{' '}
            <b>{providerName} via Opaline</b> at <b>{email}</b>.
            <br />
            <br />
            You will still have access to your treatment recommendations by following{' '}
            <a
              className="text-[#81808d]"
              href={`/my-results?from=${email}&onboarded=true`}
            >
              this link
            </a>{' '}
            or a link in any previous email we&apos;ve sent.
          </p>
          <div className="my-4">
            <Button shrink onClick={handleResubscribe}>
              I&apos;ve changed my mind
            </Button>
          </div>
        </>
      ) : (
        <>
          <p className="my-10 py-1 text-center text-sm">
            Great! <b>{email}</b> has been resubscribed to{' '}
            <b>{providerName} via Opaline</b>.
          </p>
          <div className="mb-4">
            <Button onClick={() => navigate(`/my-results?from=${email}&onboarded=true`)}>
              Go to my recommendations
            </Button>
          </div>
        </>
      )}
      <div className="text-[#81808d] text-xs font-light">
        <a
          className="text-inherit no-underline"
          target="_blank"
          href="https://www.joinopaline.com/privacy"
        >
          Privacy policy
        </a>
      </div>
    </div>
  );
};

export default Unsubscribe;

import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { useHeaderStore } from '@/stores/useHeaderStore';
import LeftArrow from '@/media/left-arrow.svg';

const Header = forwardRef<HTMLDivElement>((_, ref) => {
  const {
    title,
    titleLevel,
    progress,
    leftButton,
    rightButton,
    onLeftButtonClick,
    onRightButtonClick,
  } = useHeaderStore();

  const TitleTag = `h${titleLevel}` as keyof JSX.IntrinsicElements;

  const progressBar = progress !== undefined && (
    <div className="absolute top-0 left-0 w-full h-[5px] bg-gray-300">
      <div
        className="h-[5px] bg-gradient-to-r from-[rgba(41,231,205,1)] to-[rgba(125,49,245,1)]"
        style={{ width: `${Math.min(progress, 100)}%` }}
      />
    </div>
  );

  const leftButtonElement = leftButton && (
    <button onClick={onLeftButtonClick} className="mr-4 flex items-center">
      <img src={LeftArrow} className="mr-1" alt="Left Arrow" width="10" height="10" />
      {leftButton}
    </button>
  );

  const rightButtonElement = rightButton && (
    <button onClick={onRightButtonClick} className="ml-4">
      {rightButton}
    </button>
  );

  const titleClasses = classNames('text-center px-8 break-words', {
    'mt-10': !leftButton && !rightButton,
    'mt-4': leftButton || rightButton,
  });

  return (
    <header id="patient-header" className="relative w-full" ref={ref}>
      {progressBar}
      <div className="flex items-center justify-between p-4">
        {leftButtonElement}
        {rightButtonElement}
      </div>
      {title && (
        <div className={titleClasses}>
          <TitleTag>
            {title.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </TitleTag>
        </div>
      )}
    </header>
  );
});

Header.displayName = 'Header';

export default Header;

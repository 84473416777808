import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PatientRoutes from 'patient/__routes/PatientRoutes';
import AdminRoutes from 'admin/routes/AdminRoutes';
import { AuthResponseCapture } from '@/components/SocialLogin/SocialLogin';
import Link from 'patient/Link/Link';
import useFetchProviderDetails from '@/hooks/useFetchProviderDetails';
import { getSubdomain } from '@/utils/urls';
import './App.css';

function App() {
  const subdomain = getSubdomain();
  useFetchProviderDetails(subdomain);

  return (
    <Router>
      <Routes>
        <Route path="/*" element={<PatientRoutes />} />
        <Route path="/admin/*" element={<AdminRoutes />} />
        <Route path="/authredirect" element={<AuthResponseCapture />} />
        <Route path="/link/:token" element={<Link />} />
      </Routes>
    </Router>
  );
}

export default App;

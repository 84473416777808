import React from 'react';
import { useQuestion } from './useQuestion';
import { Question } from '../types';

interface SelectQuestionProps {
  question: Question;
}

const SelectQuestion: React.FC<SelectQuestionProps> = ({ question }) => {
  const { selectedAnswers, handleAnswerChange } = useQuestion(question);

  const handleClick = (value: string) => {
    handleAnswerChange([value]);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, value: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick(value);
    }
  };

  return (
    <div className="flex flex-col items-center space-y-4 pb-6">
      {question.answerOptions.map((option, index) => (
        <div
          key={index}
          onClick={() => handleClick(option.text)}
          onKeyDown={event => handleKeyDown(event, option.text)}
          role="button"
          tabIndex={0}
          className={`flex items-center justify-center w-75 h-12 border border-black text-sm font-medium rounded-md cursor-pointer transition ease-in-out duration-300
            ${selectedAnswers.includes(option.text) ? 'bg-gray-custom' : 'bg-transparent hover:bg-gray-50 focus:bg-gray-100 focus:outline-none'}`}
          style={{
            width: '300px',
            fontFamily: 'Spline Sans, sans-serif',
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '20px',
            textAlign: 'center',
          }}
        >
          {option.text}
        </div>
      ))}
      {question.disclaimer && (
        <p className="pt-4 font-light text-center text-sm">{question.disclaimer}</p>
      )}
    </div>
  );
};

export default SelectQuestion;

import React, { useState } from 'react';
import { useQuestion } from './useQuestion';
import { Question } from '../types';
import {
  useGetConcernsByProductAndBodyPartQuery,
  BodyPart,
} from '@/graphql/generated/graphql';
import { useQuizStore } from '@/stores/useQuizStore';
import Pill from '@/components/Pill/Pill';

interface ConcernsQuestionProps {
  question: Question;
}

const ConcernsQuestion: React.FC<ConcernsQuestionProps> = ({ question }) => {
  const answers = useQuizStore(state => state.answers);
  const { selectedAnswers, handleAnswerChange } = useQuestion(question);
  const [disabledAnswers, setDisabledAnswers] = useState({});
  const { loading, error, data } = useGetConcernsByProductAndBodyPartQuery({
    variables: {
      bodyPart: answers.treatmentAreas[0] === 'Body' ? BodyPart.Body : BodyPart.Face,
    },
  });

  const handleChange = (answer: string) => {
    const answerInState = selectedAnswers.includes(answer);

    if (!answerInState && selectedAnswers.length < 3) {
      const index = selectedAnswers.length;
      const updatedAnswers = [...selectedAnswers];
      updatedAnswers[index] = answer;
      handleAnswerChange(updatedAnswers);
      setDisabledAnswers({
        ...disabledAnswers,
        [index]: updatedAnswers[index],
      });
    }
  };

  const handleRemoveAnswer = (answer: string) => {
    const updatedAnswers = selectedAnswers.filter(
      selectedAnswer => selectedAnswer !== answer
    );
    handleAnswerChange(updatedAnswers);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, answer: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleChange(answer);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  // @ts-expect-error There should be no case where this doesn't return a value.
  const sortedTags = [...data.tags].sort((a, b) => a.name.localeCompare(b.name));

  const concernSelection = () =>
    selectedAnswers.length > 0 && (
      <div>
        <p className="text-sm pb-2">
          {selectedAnswers.length < 3
            ? `You have ${3 - selectedAnswers.length} concern${selectedAnswers.length < 2 ? 's' : ''} remaining. `
            : 'Look at those selection skills! '}{' '}
          You selected:
        </p>
        <div className="flex flex-wrap justify-center gap-1 w-full">
          {selectedAnswers
            .sort((a, b) => a.localeCompare(b))
            .map(answer => (
              <Pill
                key={answer}
                text={answer}
                onClick={() => handleRemoveAnswer(answer)}
                active
              />
            ))}
        </div>
      </div>
    );

  return (
    <div className="flex flex-col items-center space-y-8 -mt-8">
      <div className="w-full mt-6">
        <div className="mt-2 relative">
          <div className="mt-2 overflow-y-auto h-[calc(100dvh-400px)] min-w-3/4">
            {sortedTags.map(concern => (
              <div
                key={concern.id}
                onClick={() => handleChange(concern.name)}
                onKeyDown={event => handleKeyDown(event, concern.name)}
                role="button"
                tabIndex={0}
                className={`flex items-center m-auto justify-center h-7 mb-2 cursor-pointer transition ease-in-out duration-300 text-sm font-thin ${
                  // If the answer is selected and in the current group, highlight it to show its the current selection.
                  selectedAnswers.includes(concern.name)
                    ? 'bg-gray-custom'
                    : 'bg-transparent hover:bg-gray-custom'
                }`}
              >
                <span
                  className={`w-full ${
                    // If the answer is selected, gray it out to show it is not selectable anymore.
                    selectedAnswers.includes(concern.name) || selectedAnswers.length === 3
                      ? 'text-gray-400'
                      : ''
                  }`}
                >
                  {concern.name}
                </span>
              </div>
            ))}
          </div>
          <div className="fade-out-overlay"></div>
        </div>
      </div>
      {concernSelection()}
    </div>
  );
};

export default ConcernsQuestion;

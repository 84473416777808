import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import EntryPage from './EntryPage';
import EnterCodePage from './EnterCodePage';
import CompleteProfilePage from './CompleteProfilePage';
import { useHeaderStore } from '@/stores/useHeaderStore';
import { useUserStore } from '@/stores/useUserStore';
import { useQuizStore } from '@/stores/useQuizStore';
import {
  useSendResultsEmailMutation,
  useCreateQuizResultMutation,
  useGetTagsQuery,
} from '@/graphql/generated/graphql';
import { useFooterStore } from '@/stores/useFooterStore';
import Container from '@/components/Container/Container';

/**
 * Expected behavior of this page:
 * For a new user, the user profile in session storage will be unpopulated (null), and the normal flow will start:
 *   User selects a verification method > confirms email and completes profile if necessary > progresses to results
 * Users with existing user sessions that refresh or navigate to the page are handled as follows:
 *   On page refresh, session storage maintains page state, continue as normal
 *   On navigation from a later page, session storage state isLoggedIn, user is sent to my-results
 *   On navigation from an earlier page, should ideally display "oops, take me back" [TBD post alpha - currently unaware of earlier application state ]
 * Existing users without existing user sessions that navigate to the page:
 *   If they've gone through the quiz again, the first step is the same, email verification counts as authentication, no need to re-enter profile
 *   If they've just navigated to the page (e.g., closed browser and re-opened), same, restart process/reauthenticate from first step
 */

// Persist page state to session storage
export type LogInPageStateEnum =
  | 'LOGGED_OUT'
  | 'CODE_SENT'
  | 'PARTIAL_PROFILE'
  | 'LOGGED_IN';

export type LogInTypeEnum = 'AUTHENTICATE' | 'GET_MY_RESULTS';

interface LogInProps {
  logInType: LogInTypeEnum;
}

const LogIn: React.FC<LogInProps> = ({ logInType }) => {
  const navigate = useNavigate();
  const { user: currentUser, isLoggedIn } = useUserStore();
  const { setLeftButton, setTitle, setProgress } = useHeaderStore();
  const { setButtonText } = useFooterStore();

  // Persist state to protect against accidental refresh during authentication flow
  // Not using a store to avoid the persistence being maintained post-flow
  const restoreState = sessionStorage.getItem('opaline-user-login-progress');
  const initialState = restoreState
    ? JSON.parse(restoreState)
    : {
        logInState: 'LOGGED_OUT',
        emailToVerify: '',
      };
  const [logInState, setLogInState] = useState(initialState.logInState);
  const [emailToVerify, setEmailToVerify] = useState(initialState.emailToVerify);
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  useEffect(() => {
    sessionStorage.setItem(
      'opaline-user-login-progress',
      JSON.stringify({
        logInState,
        emailToVerify,
      })
    );
  }, [logInState, emailToVerify]);

  const redirectRoute = `/${useParams()['*']}`; // Should be populated if logInType is AUTHENTICATE
  const [searchParams] = useSearchParams();
  const fromEmail = searchParams.get('from') || ''; // Should be populate if clicked through from email

  useEffect(() => {
    setLeftButton('');
    setTitle('');
    setProgress(95);
    setButtonText('');
  }, [setLeftButton, setTitle, setProgress, setButtonText]);

  const { data: tagData, loading: tagsLoading } = useGetTagsQuery();
  const rawQuizData = useQuizStore(state => state);
  const answers = useQuizStore(state => state.answers);
  const previousQuizId = useQuizStore(state => state.quizResultsId);
  const emailResultsSent = useQuizStore(state => state.emailResultsSent);
  const setEmailResultsSent = useQuizStore(state => state.setEmailResultsSent);
  const setQuizResultsId = useQuizStore(state => state.setQuizResultsId);

  const [CreateQuizResult, { data, loading, error }] = useCreateQuizResultMutation();
  const [sendResultsEmailMutation] = useSendResultsEmailMutation();

  // Parent level page state logic
  useEffect(() => {
    // Store quiz result on successful initial sign-up
    const createQuizResult = async (userId: string) => {
      if (submissionInProgress) return;

      try {
        setSubmissionInProgress(true);

        if (tagsLoading || loading) return;
        if (!error && !loading && data) {
          const quizResultsId = data.createQuizResult.id;
          if (previousQuizId !== quizResultsId || !emailResultsSent) {
            sendResultsEmailMutation();
            setEmailResultsSent(true);
          }
          setQuizResultsId(quizResultsId);
          navigate('/my-results');
          return;
        }

        const selectedBodyPartId = tagData?.tags
          .filter(t => t.type === 'BODYPART' && t.name === answers.treatmentAreas[0])
          .flatMap(tag => tag.id);

        const selectedConcernIds = tagData?.tags
          .filter(tag => answers.concerns.includes(tag.name))
          .flatMap(tag => tag.id);

        const result = await CreateQuizResult({
          variables: {
            userId: userId,
            bodyPartId: selectedBodyPartId ? selectedBodyPartId[0] : '',
            concernIds: selectedConcernIds ? [...selectedConcernIds] : [],
            rawQuizData,
          },
        });

        // Ensure that the state updates do not cause infinite loops
        if (result.data) {
          const quizResultsId = result.data.createQuizResult.id;
          if (previousQuizId !== quizResultsId || !emailResultsSent) {
            sendResultsEmailMutation();
            setEmailResultsSent(true);
          }
          setQuizResultsId(quizResultsId);
          navigate('/my-results');
        } else {
          console.error('Error: result.data is null or undefined');
        }
      } catch (e) {
        console.error('Error creating quiz result:', e);
        // TODO: navigate('/error?incomingRoute=${location}');
      } finally {
        setSubmissionInProgress(false);
      }
    };
    // Update page state in response to hydration of UserState after login
    switch (logInState) {
      case 'LOGGED_OUT':
        // Starting state, display EntryPage below
        // If user selects email verification, EntryPage will trigger email send & redirect to CODE_SENT
        // If user selects social login, some but not all profile information will be received from the social provider
        if (currentUser) {
          setLogInState(isLoggedIn ? 'LOGGED_IN' : 'PARTIAL_PROFILE');
        }
        break;
      case 'CODE_SENT': // [TBD: "Resend" option in case code expires, ideally would be issued by backend
        // Code sent via email
        // If user enters code correctly, EnterCodePage will attempt to log in, and UserState will be populated (unless an error occurs)
        // In the normal new user flow there will not yet be enough profile information to set isLoggedIn
        if (currentUser) {
          setLogInState(isLoggedIn ? 'LOGGED_IN' : 'PARTIAL_PROFILE');
        }
        break;
      case 'PARTIAL_PROFILE':
        // A change in user state here should only be possible if the profile has been completed
        // Theoretically possible that the user could be logged out by token invalidation
        if (isLoggedIn) {
          setLogInState('LOGGED_IN');
        }
        if (!currentUser) {
          setLogInState('LOGGED_OUT');
        }
        break;
      case 'LOGGED_IN':
        if (!isLoggedIn) {
          // Error state, restart
          console.log('User profile missing, restarting login process');
          setLogInState('LOGGED_OUT');
        }
        if (currentUser) {
          // Flow complete
          sessionStorage.removeItem('opaline-user-login-progress');

          if (logInType === 'GET_MY_RESULTS') {
            createQuizResult(currentUser.id);
          } else if (logInType === 'AUTHENTICATE') {
            let searchSlug = searchParams.toString().replace(/from=[^&]*&?/, '');
            if (searchSlug) searchSlug = `?${searchSlug}`;
            useQuizStore
              .getState()
              .rehydrateQuiz(currentUser.id)
              .then(() => navigate(redirectRoute + searchSlug));
          }
        }
        break;
    }
  }, [
    logInState,
    currentUser,
    isLoggedIn,
    logInType,
    searchParams,
    navigate,
    redirectRoute,
    CreateQuizResult,
    answers.concerns,
    answers.treatmentAreas,
    data,
    emailResultsSent,
    error,
    loading,
    previousQuizId,
    rawQuizData,
    sendResultsEmailMutation,
    setEmailResultsSent,
    setQuizResultsId,
    submissionInProgress,
    tagData?.tags,
    tagsLoading,
  ]);

  return (
    <Container centerVertical>
      <div className="bg-white p-14 rounded-lg w-full max-w-md text-center flex flex-col items-center">
        {logInState === 'LOGGED_OUT' ? (
          <EntryPage
            logInType={logInType}
            defaultEmail={fromEmail}
            setLogInState={setLogInState}
            setEmailToVerify={setEmailToVerify}
          />
        ) : logInState === 'CODE_SENT' ? (
          <EnterCodePage setLogInState={setLogInState} emailToVerify={emailToVerify} />
        ) : logInState === 'PARTIAL_PROFILE' ? (
          <CompleteProfilePage />
        ) : null}
      </div>
    </Container>
  );
};

export default LogIn;

import { create } from 'zustand';

interface FooterState {
  poweredBy?: boolean;
  setPoweredBy: (poweredBy: boolean) => void;
  disableButton?: boolean;
  setDisableButton: (disableButton: boolean) => void;
  buttonText?: string;
  secondaryButtonText?: string;
  setButtonText: (buttonText: string, secondaryButtonText?: string) => void;
  onButtonClick?: () => void;
  setOnButtonClick: (callback: () => void) => void;
  onSecondaryButtonClick?: () => void;
  setOnSecondaryButtonClick: (callback: () => void) => void;
  transparent?: boolean;
  setTransparent: (transparent: boolean) => void;
}

export const useFooterStore = create<FooterState>(set => ({
  poweredBy: true,
  setPoweredBy: (poweredBy: boolean) => set({ poweredBy }),
  disableButton: false,
  setDisableButton: (disableButton: boolean) => set({ disableButton }),
  buttonText: undefined,
  secondaryButtonText: undefined,
  setButtonText: (buttonText: string, secondaryButtonText: string = '') =>
    set({ buttonText, secondaryButtonText }),
  onButtonClick: undefined,
  setOnButtonClick: (callback: () => void) => set({ onButtonClick: callback }),
  onSecondaryButtonClick: undefined,
  setOnSecondaryButtonClick: (callback: () => void) =>
    set({ onSecondaryButtonClick: callback }),
  transparent: false,
  setTransparent: (transparent: boolean) => set({ transparent }),
}));

import { Observable, FetchResult, fromPromise } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { apolloClient } from '@/graphql/ApolloProvider';
import { RefreshTokenDocument } from '@/graphql/generated/graphql';
import { getAccessToken, setAccessToken, logOut } from '@/stores/useUserStore';

// Insert Authorization header when logged in
const headerLink = setContext((_, { headers }) => {
  const accessToken = getAccessToken();

  return accessToken
    ? {
        headers: {
          ...headers,
          authorization: `Bearer ${accessToken}`,
        },
      }
    : headers;
});

// Handle 401
let tokenRefreshInProgress: boolean = false;
let pendingUnauthorizedQueryRetries: (() => void)[] = []; // Promise.resolve callbacks for pending queries

const unauthorizedLink = onError(({ graphQLErrors, operation, forward }) => {
  if (
    !graphQLErrors ||
    !graphQLErrors.find(err => err.extensions!.code === 'UNAUTHENTICATED')
  )
    return;

  // Catch missing or rejected refresh token and trigger reauthentication
  // Query will not be retried => graceful reload after authentication relies on pages cleanly handling query failure
  if (operation.operationName === 'RefreshToken') {
    logOut();
    window.location.reload();
  }

  // Ref https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8
  let blockingForward: Observable<void>; // Observable that substitutes for original query until token refreshed
  if (!tokenRefreshInProgress) {
    // Initiate token refresh, generate promise that resolves once refresh is complete
    tokenRefreshInProgress = true;

    blockingForward = fromPromise(
      apolloClient
        .mutate({
          mutation: RefreshTokenDocument,
          fetchPolicy: 'no-cache',
        })
        .then((response: FetchResult) => {
          const { accessToken } = response.data!.authRefreshToken;
          setAccessToken(accessToken);
          console.log('Access token refreshed');

          // Resolve any promise associated with pending queries other than the original
          pendingUnauthorizedQueryRetries.map((resolveCallback: () => void) =>
            resolveCallback()
          );
        })
        .catch(response => {
          console.log('Refresh token error', response.errors);
        })
        .finally(() => {
          pendingUnauthorizedQueryRetries = [];
          tokenRefreshInProgress = false;
        })
    );
  } else {
    // Create promise & queue resolve callback for this query
    blockingForward = fromPromise(
      new Promise(resolve => {
        pendingUnauthorizedQueryRetries.push(() => resolve());
      })
    );
  }

  // Map promise resolution for this query (completion of the refresh) to trigger of the retry
  return blockingForward.flatMap(() => forward(operation));
});

const authLink = unauthorizedLink.concat(headerLink);
export default authLink;

import React, { useState, useRef, useEffect } from 'react';
import { FullStory } from '@fullstory/browser';
import logUserAction from '@/log/logUserAction';
import Button from '@/components/Button/Button';
import Input from '@/components/Input/Input';
import SocialLogin from '@/components/SocialLogin/SocialLogin';
import { useStartEmailVerificationMutation } from '@/graphql/generated/graphql';
import { LogInTypeEnum, LogInPageStateEnum } from './LogIn';

interface EntryPageProps {
  logInType: LogInTypeEnum;
  defaultEmail: string;
  setLogInState: (newState: LogInPageStateEnum) => void;
  setEmailToVerify: (email: string) => void;
}

// Initial screen, choice of email verification or social options
const EntryPage: React.FC<EntryPageProps> = ({
  logInType,
  defaultEmail,
  setLogInState,
  setEmailToVerify,
}) => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [showValidityError, setShowValidityError] = useState(false);
  const [startEmailVerificationMutation] = useStartEmailVerificationMutation();

  useEffect(() => {
    if (defaultEmail) {
      emailInputRef.current!.value = defaultEmail;
    }
  }, [defaultEmail]);

  useEffect(() => {
    FullStory('trackEvent', {
      name: `Login/Entrypage`,
      properties: {},
    });
    logUserAction('> EntryPage');
  }, []);

  const handleSendEmailButtonClick = () => {
    const emailInput = emailInputRef.current!;

    if (emailInput.value && !emailInput.validity.valid) {
      setShowValidityError(true);
    } else {
      startEmailVerificationMutation({
        variables: {
          email: emailInput.value,
        },
      })
        .then(() => {
          setEmailToVerify(emailInput.value);
          setLogInState('CODE_SENT');
        })
        .catch(error => {
          console.log('Failed to send verification email', error);
        });
    }
  };

  let headerMessage, emailButtonLabel;
  if (logInType === 'GET_MY_RESULTS') {
    headerMessage = 'Where do we send your results?';
    emailButtonLabel = 'Send to my email';
  } else if (logInType === 'AUTHENTICATE') {
    headerMessage = 'Login to see your recommendations';
    emailButtonLabel = 'Send a code to my email';
  }

  return (
    <>
      <h2 className="text-center">{headerMessage}</h2>
      <div className="mt-8 mb-4">
        {/* use form wrapper & name attribute to trigger autofill */}
        <form onSubmit={event => event.preventDefault()}>
          <Input
            inputName="email"
            inputRef={emailInputRef}
            inputType="EMAIL"
            withLabel
            labelText={emailButtonLabel}
            autoComplete="email"
            handleOnInput={() => setShowValidityError(false)}
          />
          {showValidityError && (
            <p className="py-1 text-center text-sm">
              Sorry, there&apos;s a problem with that address
              <br />
              Please check it and try again
            </p>
          )}
        </form>
      </div>
      <Button withIcon shrink onClick={handleSendEmailButtonClick}>
        Send to my email
      </Button>
      <p className="text-center my-6 text-sm font-bold">or</p>
      <SocialLogin />
      {logInType === 'GET_MY_RESULTS' && (
        <p className="text-xs fixed bottom-16 text-[rgba(129,128,141,1)]">
          By proceeding you agree to our{' '}
          <a
            className="text-inherit; underline"
            target="_blank"
            href="https://www.joinopaline.com/privacy"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </p>
      )}
    </>
  );
};

export default EntryPage;

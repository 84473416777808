import { Tag, Product } from '@/graphql/generated/graphql';
export type TagWithActive = Tag & { active: boolean };

/**
 * filterConcern()
 * @param concerns
 * @returns Tag[], a list of tags filtered by type of CONCERN
 */
export const filterConcerns = (concerns: Tag[]): Tag[] => {
  return concerns.filter(concern => concern.type === 'CONCERN');
};

/**
 * matchConcerns()
 * Processes all concerns provided, appending active flag to object when it matches the users
 * selected concerns. Sorts by matches first, then abc order.
 */
export const matchConcerns = (
  concerns: Tag[],
  selectedConcerns: Tag[]
): TagWithActive[] => {
  // Create a set of selected concern names for quick lookup
  const selectedConcernNames = new Set(
    filterConcerns(selectedConcerns).map(concern => concern.name)
  );

  // Map through concerns and add the active key
  const updatedConcerns = filterConcerns(concerns)
    .map(concern => ({
      ...concern,
      active: selectedConcernNames.has(concern.name),
    }))
    .filter((concern, index, self) => {
      return index === self.findIndex(t => t.id === concern.id);
    });

  // Sort the concerns first by active status, then alphabetically by name
  updatedConcerns.sort((a, b) => {
    if (a.active === b.active) {
      return a.name.localeCompare(b.name);
    }
    return a.active ? -1 : 1;
  });

  return updatedConcerns;
};

/**
 * getConcernsFromProducts()
 * Takes a product, and processes its chain of children collecting unique concerns related
 * to that product. Returns the unique list if there are concerns tied to the product and its children.
 */
export const getConcernsFromProducts = (product: Product): Tag[] => {
  const concerns = [...filterConcerns(product.tags)];

  const uniqueConcerns = Array.from(new Set(concerns.map(tag => tag.id)))
    .map(id => concerns.find(tag => tag.id === id))
    .filter((tag): tag is Tag => tag !== undefined);

  return uniqueConcerns.length > 0 ? uniqueConcerns : [];
};

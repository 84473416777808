import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useUserStore } from '@/stores/useUserStore';

const ProtectedRoute: React.FC = () => {
  const isLoggedIn = useUserStore(state => state.isLoggedIn);
  const location = useLocation();
  const route = location.pathname + location.search + location.hash;

  if (!isLoggedIn) return <Navigate to={`/login${route}`} replace />;

  return <Outlet />;
};

export default ProtectedRoute;

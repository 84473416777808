/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { useHeaderStore } from '@/stores/useHeaderStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuizStore } from '@/stores/useQuizStore';
import SL from '@/components/SpinnerLoader/SpinnerLoader';
import QuizResultLoader from './QuizResultLoader';
import TreatmentBlock from './TreatmentBlock';
import {
  QuizResultProduct,
  useGetQuizResultByIdQuery,
  Tag,
} from '@/graphql/generated/graphql';
import { getUniqueQuizProductGroups, UniqueQuizProductGroup } from '@/utils/products';
import { useFooterStore } from '@/stores/useFooterStore';
import { useProviderStore } from '@/stores/useProviderStore';
import logUserAction from '@/log/logUserAction';

const Results: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const onboarded = params.get('onboarded');

  // Component State
  const [moreResults, setMoreResults] = useState(false);
  const [moreOtherResults, setMoreOtherResults] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState(!onboarded);
  const [showContent, setShowContent] = useState(false);
  const [recommendedPGs, setRecommendPGs] = useState<UniqueQuizProductGroup[]>([]);
  const [otherPGs, setOtherPGs] = useState<UniqueQuizProductGroup[]>([]);
  const [selectedConcerns, setSelectedConcerns] = useState<Tag[]>([]);

  // External State
  const { setLeftButton, setTitle, setTitleLevel, setRightButton, setOnLeftButtonClick } =
    useHeaderStore();
  const { quizResultsId, resetQuiz } = useQuizStore();
  const { setButtonText, setOnButtonClick } = useFooterStore();
  const { settings } = useProviderStore();
  const { loading, error, data } = useGetQuizResultByIdQuery({
    variables: { id: quizResultsId },
  });

  useEffect(() => {
    const handleRetakeQuiz = () => {
      const confirmed = window.confirm('Are you sure you want to retake the quiz?');
      if (confirmed) {
        resetQuiz();
        setRightButton('');
        navigate('/quiz/onboarding');
      }
    };

    setLeftButton('Retake Quiz');
    setRightButton('Book Now');
    setOnLeftButtonClick(() => handleRetakeQuiz());
    setTitle('');
  }, [
    setLeftButton,
    setRightButton,
    setOnLeftButtonClick,
    setTitle,
    setTitleLevel,
    navigate,
    resetQuiz,
  ]);

  useEffect(() => {
    if (showContent && !isOnboarding) {
      setButtonText('Need more help? Text us');
      setOnButtonClick(() => window.open(settings!.links['Contact'], '_blank'));
    } else {
      setButtonText('');
    }
  }, [showContent, isOnboarding, setButtonText, setOnButtonClick, settings]);

  useEffect(() => {
    if (data && data.quizResults && data.quizResults.length > 0) {
      logUserAction(`VIEW_QUIZ_RESULTS_ID: ${data.quizResults[0].id}`);
      const { concerns, quizResultsProducts } = data.quizResults[0];

      if (quizResultsProducts && quizResultsProducts.length > 0) {
        const productGroups = getUniqueQuizProductGroups(
          quizResultsProducts as QuizResultProduct[]
        );
        setRecommendPGs(productGroups.filter(pg => pg.type !== 'PARTIAL_MATCH'));
        setOtherPGs(productGroups.filter(pg => pg.type === 'PARTIAL_MATCH'));
        setSelectedConcerns(concerns as Tag[]);
        setShowContent(true);
        console.log('Quiz Results:', quizResultsProducts);
      }
    }
  }, [data]);

  if ((loading || isOnboarding) && !onboarded) {
    return (
      <QuizResultLoader
        handleDoneLoading={() => setTimeout(() => setIsOnboarding(false), 100)}
        speed={100}
      />
    );
  }

  if (!loading && error) return <p>Error: {error?.message || 'Something went wrong.'}</p>;

  const renderProductGroupCards = (
    productGroups: UniqueQuizProductGroup[],
    moreResultState: boolean,
    setShowAllResultState: React.Dispatch<React.SetStateAction<boolean>>
  ) => (
    <>
      {productGroups
        .slice(0, moreResultState ? productGroups.length : 3)
        .map(productGroup => (
          <TreatmentBlock
            title={productGroup.name}
            concerns={productGroup.tags}
            selectedConcerns={selectedConcerns}
            description={productGroup.description || ''}
            onClick={() => navigate(`/my-results/pgp/${productGroup.id}`)}
            key={productGroup.id}
            onlySelectedConcerns
          />
        ))}
      {productGroups.length > 3 && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <p
          className="text-center text-sm hover:text-blue-950 underline underline-offset-2 mb-10"
          onClick={() => setShowAllResultState(!moreResultState)}
        >
          {!moreResultState ? 'Show More' : 'Show Less'}
        </p>
      )}
    </>
  );

  return (
    <>
      <SL fadeOut={showContent} />
      {showContent && (
        <>
          <div className="flex flex-col justify-center items-center pb-8">
            <h2 className="text-center mt-4 mb-8">
              Here&apos;s what
              <br />
              we recommend:
            </h2>
            {recommendedPGs.length > 0 &&
              renderProductGroupCards(recommendedPGs, moreResults, setMoreResults)}
            {otherPGs.length > 0 && (
              <div>
                {recommendedPGs.length > 0 && (
                  <h2 className="text-center mt-4 mb-8">Other matches:</h2>
                )}
                {renderProductGroupCards(otherPGs, moreOtherResults, setMoreOtherResults)}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Results;

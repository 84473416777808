import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  useDecodeLinkTokenQuery,
  DecodeLinkTokenQuery,
} from '@/graphql/generated/graphql';
import logUserAction from '@/log/logUserAction';
import { FullStory } from '@fullstory/browser';

// Expects at least userId, user email, link type, and target url to be present in the token
// All other properties in the token are passed directly to FullStory
const Link: React.FC = () => {
  const { token } = useParams();
  const { data } = useDecodeLinkTokenQuery({ variables: { token: token! } });

  const logClickAndRedirect = async ({ decodeLinkToken }: DecodeLinkTokenQuery) => {
    const { userId, email, link, ...properties }: Record<string, string> =
      decodeLinkToken;

    // TODO - proper error handling once the page is available

    logUserAction(`${link}_LINK_CLICKED_BY: ${userId}`);

    try {
      await FullStory('setPropertiesAsync', {
        type: 'user',
        properties: {
          id: userId,
          email,
        },
      });

      await FullStory('trackEventAsync', {
        name: `Email ${link} Link Clicked`,
        properties,
      });
    } catch (error) {
      // Lambda should catch any FS failure for now
    }

    window.location.href = properties.url;
  };

  useEffect(() => {
    if (data) logClickAndRedirect(data);
  }, [data]);

  return null;
};

export default Link;

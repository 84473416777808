import React from 'react';
import { Page as PagePropType } from '../types';

interface PageProps {
  content: PagePropType;
}

const Page: React.FC<PageProps> = ({ content }) => {
  return (
    <div className="flex items-center justify-center">
      <div>
        <h2 className="mb-4">{content.text}</h2>
        <p className="text-sm mx-7">{content.disclaimer}</p>
      </div>
    </div>
  );
};

export default Page;

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../Login/Login';
// import LeadTable from '../Leads/Leads.Table';
// import AdminHeader from '@/components/AdminHeader/AdminHeader';

// interface AuthenticatedRouteProps {
//   children: React.ReactNode;
// }

// const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ children }) => {
// const token = localStorage.getItem('token');
// return token ? <>{children}</> : <Navigate to="/signin" />;
// };
// const HeaderLinks = [
//   { name: 'Consults/Leads', href: '/admin/home' },
//   { name: 'Campaigns', href: '/admin/campaigns' },
//   { name: 'Reports', href: '/admin/reports' },
// ];

const PatientRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      {/* <Route
        path="/home"
        element={
          <>
            <AdminHeader links={HeaderLinks} />
            <LeadTable />
          </>
        }
      /> */}
    </Routes>
  );
};

export default PatientRoutes;

import React, { forwardRef } from 'react';
import { useFooterStore } from '@/stores/useFooterStore';
import logo from '@/media/logo-gray.svg';
import chatIcon from '@/media/chat-ellipsis.svg';
import Button from '../Button/Button';

const MEESHA_BRAND_COLOR = '#b76e79';

const Footer = forwardRef<HTMLDivElement>((_, ref) => {
  const {
    poweredBy,
    buttonText,
    disableButton,
    onButtonClick,
    secondaryButtonText,
    onSecondaryButtonClick,
    transparent,
  } = useFooterStore();

  // For now:
  // Infer that a single button is a chat button from the text - allows screens that want a basic button to set only buttonText and not an additional enum
  // Assume that a secondary button is always a chat button

  const primaryButtonIsChat = buttonText && buttonText.includes('Text us');

  const floatButtonElement =
    buttonText &&
    (!secondaryButtonText ? (
      !primaryButtonIsChat ? (
        <Button onClick={onButtonClick} disabled={disableButton} withIcon>
          {buttonText}
        </Button>
      ) : (
        <Button
          onClick={onButtonClick}
          disabled={disableButton}
          withIconLeft
          icon={chatIcon}
          bgColor={MEESHA_BRAND_COLOR}
        >
          {buttonText}
        </Button>
      )
    ) : (
      <div className="flex justify-between">
        <Button fixedWidth={'w-[50%]'} onClick={onButtonClick} disabled={disableButton}>
          {buttonText}
        </Button>
        <Button
          fixedWidth={'w-[40%]'}
          withIconLeft
          icon={chatIcon}
          bgColor={MEESHA_BRAND_COLOR}
          onClick={onSecondaryButtonClick}
          disabled={disableButton}
        >
          {secondaryButtonText}
        </Button>
      </div>
    ));

  return (
    <footer
      id="patient-footer"
      className="w-full max-w-screen-sm self-center flex flex-col content-center px-8"
      ref={ref}
    >
      {floatButtonElement}
      {poweredBy && (
        <div
          className="flex justify-center items-center relative py-2 h-8 text-[rgba(129,128,141,1)]"
          style={{ backgroundColor: transparent ? 'transparent' : 'white' }}
        >
          <span className="mr-0.5 text-[9px]">Powered by</span>
          <img src={logo} alt="Opaline Logo" className="h-2.5" />
        </div>
      )}
    </footer>
  );
});

Footer.displayName = 'Footer';

export default Footer;

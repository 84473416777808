import { create } from 'zustand';

interface HeaderState {
  title?: string;
  setTitle: (title: string) => void;
  titleLevel?: 1 | 2;
  setTitleLevel: (titleLevel: 1 | 2) => void;
  progress?: number;
  setProgress: (progress: number) => void;
  leftButton?: string;
  setLeftButton: (leftButton: string) => void;
  rightButton?: string;
  setRightButton: (rightButton: string) => void;
  onLeftButtonClick?: () => void;
  setOnLeftButtonClick: (callback: () => void) => void;
  onRightButtonClick?: () => void;
  setOnRightButtonClick: (callback: () => void) => void;
}

export const useHeaderStore = create<HeaderState>(set => ({
  title: undefined,
  setTitle: (title: string) => set({ title }),
  titleLevel: 1,
  setTitleLevel: (titleLevel: 1 | 2) => set({ titleLevel }),
  progress: undefined,
  setProgress: (progress: number) => set({ progress }),
  leftButton: undefined,
  setLeftButton: (leftButton: string) => set({ leftButton }),
  rightButton: undefined,
  setRightButton: (rightButton: string) => set({ rightButton }),
  onLeftButtonClick: undefined,
  setOnLeftButtonClick: (callback: () => void) => set({ onLeftButtonClick: callback }),
  onRightButtonClick: undefined,
  setOnRightButtonClick: (callback: () => void) => set({ onRightButtonClick: callback }),
}));

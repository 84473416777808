import React from 'react';

interface BlurbProps {
  text: string;
  maxLength?: number;
  buttonText?: string;
  onClick?: () => void;
}

const Blurb: React.FC<BlurbProps> = ({
  text,
  maxLength = 130,
  buttonText = 'More details',
  onClick,
}) => {
  const shortenDescription = (): string => {
    // No need to shorten if below maxLength
    if (text.length <= maxLength) return text;
    const shortenedText = text.slice(0, maxLength).trim();

    // Ensure we don't cut off in the middle of a word
    return !text.endsWith('.') ? shortenedText : `${shortenedText}...`;
  };

  return (
    <p className="text-sm mb-6">
      {shortenDescription()}
      {onClick && (
        <>
          <br />
          <button
            className="text-blue-800 font-normal ml-1 cursor-pointer"
            onClick={onClick}
          >
            {buttonText}
          </button>
        </>
      )}
    </p>
  );
};

export default Blurb;

import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Onboarding from 'patient/Quiz/Onboarding';
import Quiz from 'patient/Quiz/Quiz';
import TakeAPhoto from 'patient/TakeAPhoto/TakeAPhoto';
import LogIn from 'patient/LogIn/LogIn';
import Results from 'patient/Results/Results';
import PGP from 'patient/PGP/PGP';
import PDP from 'patient/PDP/PDP';
import Unsubscribe from 'patient/Unsubscribe/Unsubscribe';
import ProtectedRoute from '@/components/ProtectedRoute/ProtectedRoute';
import Header from '@/components/Header/Header';
import Footer from '@/components/Footer/Footer';
import logUserAction from '@/log/logUserAction';

const PatientRoutes: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    logUserAction(`USER_ROUTE_CHANGE: ${location.pathname}`);
  }, [location.pathname]);

  useEffect(() => {
    const patientBody = document.getElementById('patient-body');
    if (patientBody) patientBody.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  return (
    <div className="h-dvh bg-slate-300 flex justify-center">
      <div
        id="patient-app"
        className="h-full w-full max-w-screen-md bg-white flex flex-col overflow-hidden"
      >
        <Header />
        <main id="patient-body" className="flex-grow overflow-auto">
          <div className="p-8 h-full">
            <Routes>
              <Route path="*" element={<Navigate to="/quiz/onboarding" replace />} />
              <Route path="/quiz" element={<Navigate to="/quiz/onboarding" replace />} />
              <Route path="/quiz/onboarding" element={<Onboarding />} />
              <Route path="/quiz/questions" element={<Quiz />} />
              <Route path="/quiz/take-a-photo" element={<TakeAPhoto />} />
              <Route
                path="/quiz/get-my-results"
                element={<LogIn logInType="GET_MY_RESULTS" />}
              />
              <Route path="/login/*" element={<LogIn logInType="AUTHENTICATE" />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/my-results" element={<Results />} />
                <Route path="/my-results/pgp/:productGroupId" element={<PGP />} />
                <Route
                  path="/my-results/pgp/:productGroupId/pdp/:productId"
                  element={<PDP />}
                />
              </Route>
              <Route path="/unsubscribe" element={<Unsubscribe />} />
              <Route path="/unsubscribe/:token" element={<Unsubscribe />} />
            </Routes>
          </div>
        </main>
        {/* TODO: Linear Ticket to fix */}
        <Footer />
      </div>
    </div>
  );
};

export default PatientRoutes;

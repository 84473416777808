import { create } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';
import { Provider, ProviderSetting } from '@/graphql/generated/graphql';

type MyStateCreator = (set: ZustandSet, get: ZustandGet, api: unknown) => ProviderState;
type ZustandGet = () => ProviderState;
type ZustandSet = (
  partial:
    | ProviderState
    | Partial<ProviderState>
    | ((state: ProviderState) => ProviderState | Partial<ProviderState>),
  replace?: boolean | undefined
) => void;
interface ProviderState {
  provider: Provider | null;
  providerId: string | null;
  settings: ProviderSetting | null;
  setProvider: (provider: Provider) => void;
  setProviderId: (providerId: string) => void;
  setSettings: (settings: ProviderSetting) => void;
  invalidateSettings: () => void;
}

export const useProviderStore = create<ProviderState>(
  persist<ProviderState>(
    set => ({
      provider: null,
      providerId: null,
      settings: null,
      setProvider: provider => set({ provider }),
      setSettings: settings => set({ settings }),
      setProviderId: providerId => set({ providerId }),
      invalidateSettings: () => set({ settings: null }),
    }),
    {
      name: 'opaline-provider-storage',
    } as PersistOptions<ProviderState>
  ) as MyStateCreator
);

import { create } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';
import { apolloClient } from '@/graphql/ApolloProvider';
import { Answers as AnswersType } from 'patient/Quiz/types';
import { GetRawQuizDataByUserIdDocument } from '@/graphql/generated/graphql';
import logUserAction from '@/log/logUserAction';

type MyStateCreator = (set: ZustandSet, get: ZustandGet, api: unknown) => QuizState;
type ZustandGet = () => QuizState;
type ZustandSet = (
  partial:
    | QuizState
    | Partial<QuizState>
    | ((state: QuizState) => QuizState | Partial<QuizState>),
  replace?: boolean | undefined
) => void;

interface QuizState {
  currentQuestionIndex: number;
  answers: AnswersType;
  setCurrentQuestionIndex: (index: number) => void;
  setAnswer: (key: string, answer: string[]) => void;
  resetQuiz: () => void;
  rehydrateQuiz: (userId: string) => Promise<void>;
  quizResultsId: string;
  setQuizResultsId: (id: string) => void;
  emailResultsSent: boolean;
  setEmailResultsSent: (emailResultsSent: boolean) => void;
}

export const useQuizStore = create<QuizState>(
  persist<QuizState>(
    set => ({
      currentQuestionIndex: 0,
      answers: {},
      setCurrentQuestionIndex: index => {
        set(state => {
          logUserAction(
            `USER_AT_QUESTION_INDEX: ${index} : ${JSON.stringify(state.answers)}`
          );
          return { currentQuestionIndex: index };
        });
      },
      setAnswer: (key, answer) =>
        set(state => ({ answers: { ...state.answers, [key]: answer } })),
      resetQuiz: () => set({ currentQuestionIndex: 0, answers: {} }),
      rehydrateQuiz: async (userId: string) => {
        const newState = await apolloClient
          .query({
            query: GetRawQuizDataByUserIdDocument,
            variables: { userId }, // Should be able to use access token directly
            fetchPolicy: 'network-only',
          })
          .then(({ data }) => {
            const rawQuizData = data.quizResults[data.quizResults.length - 1].rawQuizData;
            if (!rawQuizData) throw new Error(); // Db has not been synced
            return rawQuizData;
          })
          .catch(error => {
            console.log('Failed to rehydrate quiz store', error);
            return { currentQuestionIndex: 0, answers: {} };
          });
        set(newState);
      },
      quizResultsId: '',
      setQuizResultsId: id => set({ quizResultsId: id }),
      emailResultsSent: false,
      setEmailResultsSent: emailResultsSent => set({ emailResultsSent }),
    }),
    {
      name: 'opaline-quiz-storage',
    } as PersistOptions<QuizState>
  ) as MyStateCreator
);

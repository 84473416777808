import React from 'react';
import classNames from 'classnames';

interface ButtonProps {
  buttonRef?: React.RefObject<HTMLButtonElement>;
  floatBottom?: boolean;
  icon?: string;
  withIcon?: boolean;
  withIconLeft?: boolean;
  shrink?: boolean;
  fixedWidth?: string;
  bgColor?: string;
  onClick?: () => void;
  disabled?: boolean;
  children: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  buttonRef,
  floatBottom = false,
  icon,
  withIcon = false,
  withIconLeft = false,
  shrink = false,
  fixedWidth,
  bgColor = 'black', // Use inline style vs. Tailwind to support any brand color
  onClick,
  disabled = false,
  children,
}) => {
  const baseClasses =
    'flex items-center justify-center h-12 transition duration-300 ease-in-out z-10';
  const gradientClasses =
    'bg-gradient-to-r from-[rgba(41,231,205,1)] to-[rgba(125,49,245,1)] rounded-full p-0.5';
  const widthClasses = fixedWidth || (shrink ? 'min-w-60' : 'min-w-80');
  const svgClasses = `flex w-full h-full items-center justify-center text-white rounded-full font-normal h-10 rounded-full font-sans text-lg`;
  const floatBottomClasses = 'fixed bottom-12 left-1/2 transform -translate-x-1/2';
  const buttonClasses = classNames(baseClasses, widthClasses, {
    [gradientClasses]: bgColor === 'black',
    [floatBottomClasses]: floatBottom,
    'opacity-50 cursor-not-allowed': disabled,
    'hover:opacity-100': !disabled,
  });

  const displayIcon = icon ? (
    <img className="m-2 pt-1" src={icon} alt="" />
  ) : (
    <svg
      className="w-4 h-4 ml-2 z"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 5l7 7-7 7"
      ></path>
    </svg>
  );

  return (
    <button
      ref={buttonRef}
      className={buttonClasses}
      onClick={onClick}
      disabled={disabled}
    >
      <span className={svgClasses} style={{ backgroundColor: bgColor }}>
        {withIconLeft && displayIcon}
        {children}
        {withIcon && displayIcon}
      </span>
    </button>
  );
};

export default Button;

import React from 'react';
import Line from '@/components/Line/Line';
import { Product } from '@/graphql/generated/graphql';

interface WhatToExpectProps {
  product: Product;
}

const WhatToExpect: React.FC<WhatToExpectProps> = ({ product }) => {
  const renderComponent =
    product.invasive ||
    product.expectations ||
    product.downtime ||
    product.disclaimer ||
    product.preTreatment ||
    product.postTreatment ||
    product.results;

  if (!renderComponent) return null;
  return (
    <div>
      <Line />
      <p className="text-xl text-center font-normal mb-3">What to expect / the details</p>
      {product.invasive && (
        <p className="pb-3 text-sm">
          <strong>Invasiveness: </strong>
          {product.invasive}
        </p>
      )}
      {product.expectations && (
        <p className="pb-3 text-sm">
          <strong>Expectations: </strong>
          {product.expectations}
        </p>
      )}
      {product.downtime && (
        <p className="pb-3 text-sm">
          <strong>Downtime: </strong>
          {product.downtime}
        </p>
      )}
      {product.disclaimer && (
        <p className="pb-3 text-sm">
          <strong>Disclaimer: </strong>
          {product.disclaimer}
        </p>
      )}
      {product.preTreatment && (
        <p className="pb-3 text-sm">
          <strong>Before your treatment: </strong>
          {product.preTreatment}
        </p>
      )}
      {product.postTreatment && (
        <p className="pb-3 text-sm">
          <strong>After your treatment: </strong>
          {product.postTreatment}
        </p>
      )}
      {product.results && (
        <p className="pb-3 text-sm">
          <strong>Results: </strong>
          {product.results}
        </p>
      )}
    </div>
  );
};

export default WhatToExpect;

import { ProductMedia } from '@/graphql/generated/graphql';
const productImagesHost = 'https://opaline-product-images.s3.us-east-1.amazonaws.com/';

export const getProductImageForDemographic = (
  productMedia: ProductMedia[],
  isMale: boolean
): string | null => {
  // Filter the media array based on the gender
  const gender = isMale ? 'Males' : 'Females';

  // Check for matching gender images
  // If matching images are found, return the first one
  // If no gender match, return the first image regardless of gender
  // If no images, return null
  const matchingImages = productMedia.filter(media => media.s3Key.includes(gender));
  if (matchingImages.length > 0) return productImagesHost + matchingImages[0].s3Key;
  if (productMedia.length > 0) return productImagesHost + productMedia[0].s3Key;
  return null;
};

import { useEffect } from 'react';
import {
  useGetProviderBySubdomainQuery,
  ProviderSetting,
  Provider,
} from '@/graphql/generated/graphql';
import { useProviderStore } from '@/stores/useProviderStore';
import { getDemoOrigin, getSubdomain } from '@/utils/urls';

const useFetchProviderDetails = (subdomain: string) => {
  const { setProvider, setProviderId, setSettings, invalidateSettings } =
    useProviderStore();
  const { data, loading, error } = useGetProviderBySubdomainQuery({
    variables: { subdomain },
  });

  useEffect(() => {
    const routeToDemo = () => window.open(getDemoOrigin(), '_self');
    if (!loading && error && getSubdomain() !== 'demo') {
      console.error('Failed to fetch provider details', error);
      routeToDemo();
    }

    if (!loading && !error && data) {
      const provider = data?.providers[0];
      const providerIsFound = data?.providers && data.providers.length > 0;

      if (providerIsFound) {
        setSettings(provider.settings as ProviderSetting);
        setProvider(provider as Provider);
        setProviderId(provider.id);
      } else {
        invalidateSettings();
        console.error('Provider not found');
        if (getSubdomain() !== 'demo') routeToDemo();
      }
    }
  }, [data, loading, error, setProvider, setProviderId, setSettings, invalidateSettings]);

  return { loading, error };
};

export default useFetchProviderDetails;

import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { apolloClient } from '@/graphql/ApolloProvider';
import {
  User,
  GetCurrentUserDocument,
  UpdateCurrentUserDocument,
  ClearRefreshCookieDocument,
} from '@/graphql/generated/graphql';
import logUserAction from '@/log/logUserAction';
import * as Sentry from '@sentry/react';
import { FullStory } from '@fullstory/browser';

// Behavior:
// accessToken is set once returned by the backend (i.e., user exists in DB with at least phone or email)
// Backend queries will be authorized once accessToken is set
// isLoggedIn is intended to be used for frontend routing of a valid user profile, is set when the user record includes at least firstname
export const requirePhoneNumber = true;

interface UserState {
  user: User | null;
  updateUser: (user: User) => void; // Assume replacement
  isLoggedIn: boolean;
}

export const useUserStore = create<UserState>()(
  persist<UserState>(
    set => ({
      user: null,
      isLoggedIn: false,
      updateUser: user => {
        set({
          user,
          isLoggedIn: user && !!user.firstname && (!!user.phone || !requirePhoneNumber),
        });

        logUserAction(`USER_ID_ASSIGNED: ${user.id}`);
        Sentry.setUser({
          id: user.id,
          username: `${user.firstname} ${user.lastname}`,
          email: `${user.email}`,
        });

        FullStory('setProperties', {
          type: 'user',
          properties: {
            id: user.id,
            displayName: `${user.firstname} ${user.lastname}`,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            phone: user.phone,
          },
        });

        apolloClient
          .mutate({
            mutation: UpdateCurrentUserDocument,
            variables: {
              id: user.id,
              email: user.email,
              phone: user.phone,
              firstname: user.firstname,
              lastname: user.lastname,
            },
          })
          .catch(error => console.log(error)); // Output for debugging, but do not crash app
      },
    }),
    {
      name: 'opaline-user-session',
      storage: createJSONStorage(() => sessionStorage),
      onRehydrateStorage: () => state => {
        if (state?.user) {
          logUserAction(`USER_ID_ASSIGNED: ${state.user.id}`);

          Sentry.setUser({
            id: state.user.id,
            username: `${state.user.firstname} ${state.user.lastname}`,
            email: `${state.user.email}`,
          });

          FullStory('setProperties', {
            type: 'user',
            properties: {
              id: state.user.id,
              displayName: `${state.user.firstname} ${state.user.lastname}`,
              firstname: state.user.firstname,
              lastname: state.user.lastname,
              email: state.user.email,
              phone: state.user.phone,
            },
          });
        }
      },
    }
  )
);

// Access token outside store, since it will only be used by auth middleware outside React components
export const setAccessToken = (accessToken: string) =>
  window.sessionStorage.setItem('opaline-user-token', accessToken);

export const getAccessToken = () => window.sessionStorage.getItem('opaline-user-token');

// Attempt login given an access token
// Will set isLoggedIn only if the profile is complete
export const logIn = (accessToken: string) => {
  setAccessToken(accessToken);

  return apolloClient
    .query({
      query: GetCurrentUserDocument,
      fetchPolicy: 'network-only',
    })
    .then(({ data }) => {
      useUserStore.getState().updateUser(data.currentUser);
    });
};

export const logOut = () => {
  apolloClient.mutate({ mutation: ClearRefreshCookieDocument }).then(({ data }) => {
    if (!data.clearRefreshCookie) console.log('Failed to clear refresh token cookie');
  });

  window.sessionStorage.removeItem('opaline-user-token');
  useUserStore.setState({ user: null, isLoggedIn: false });
};

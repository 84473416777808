// Wrapper for SDK providers for use with personalized buttons, is a strict pass-thru if personalized buttons not used
import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import FacebookOAuthProvider from '@/components/FacebookLogin/FacebookOAuthProvider';
import {
  USE_CUSTOM_BUTTONS_WITH_PKCE,
  GOOGLE_CLIENT_ID,
  META_CLIENT_ID,
} from '@/components/SocialLogin/SocialLogin';

// Provides login & logout functions to child components
const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return USE_CUSTOM_BUTTONS_WITH_PKCE ? (
    // Pass-thru
    <>{children}</>
  ) : (
    // Async load of login SDKs
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <FacebookOAuthProvider clientId={META_CLIENT_ID}>{children}</FacebookOAuthProvider>
    </GoogleOAuthProvider>
  );
};

export default AuthProvider;

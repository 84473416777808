import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePhotoStore } from '@/stores/usePhotoStore';
import { useFooterStore } from '@/stores/useFooterStore';

const PreviewPhoto: React.FC = () => {
  const navigate = useNavigate();
  const photo = usePhotoStore(state => state.photo);
  const { buttonText, setButtonText, setOnButtonClick } = useFooterStore();

  // TODO: https://linear.app/opaline/issue/DEV-184/lambda-user-images
  const handleClickGetYourAnalysis = () => navigate('/quiz/get-my-results');
  if (buttonText !== 'Next: Get your analysis') {
    setButtonText('Next: Get your analysis');
    setOnButtonClick(() => handleClickGetYourAnalysis());
  }

  return (
    <div className="text-center w-full px-9">
      <h2>How’s this?</h2>
      <div className="mt-6">
        {photo ? (
          <img src={photo} alt="Captured" className="w-full h-64 object-cover" />
        ) : (
          <div className="w-full h-96 bg-gray-200"></div>
        )}
      </div>
      {/* TODO: https://linear.app/opaline/issue/DEV-184/lambda-user-images */}
      {/* <p className="mt-4 text-sm">
        <button className="text-purple-600" onClick={() => setStep('TAKE_PHOTO')}>
          Upload
        </button>{' '}
        your own photo instead
      </p> */}
      <p className="mt-6 text-sm">
        Feeling shy?{' '}
        <button
          className="text-purple-600"
          onClick={() => navigate('/quiz/get-my-results')}
        >
          Skip this step
        </button>
      </p>
    </div>
  );
};

export default PreviewPhoto;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHeaderStore } from '@/stores/useHeaderStore';
import { useFooterStore } from '@/stores/useFooterStore';
import Container from '@/components/Container/Container';

const Onboarding: React.FC = () => {
  const navigate = useNavigate();
  const { setTitle, setTitleLevel, setProgress, setLeftButton } = useHeaderStore();
  const { setOnButtonClick, setButtonText, setTransparent } = useFooterStore();

  useEffect(() => {
    setTitle('');
    setTitleLevel(1);
    setProgress(0);
    setLeftButton('');
    setButtonText("Let's get started");
    setOnButtonClick(() => navigate('/quiz/questions'));
    setTransparent(true);
  }, [
    setTitle,
    setTitleLevel,
    setProgress,
    setLeftButton,
    navigate,
    setButtonText,
    setOnButtonClick,
    setTransparent,
  ]);

  return (
    <Container centerVertical>
      <div>
        <div className="hero-gradient"></div>
        <h1 className="mb-8 text-purple-custom">
          Your beauty.
          <br />
          Your way.
        </h1>
        <p>
          Looking for an aesthetic treatment but don&apos;t know where to start?
          <br />
          <br />
          Generate your personalized treatment recommendations in just 5 minutes!
        </p>
      </div>
    </Container>
  );
};

export default Onboarding;

import React from 'react';

interface RichTextProps {
  text: string;
  className?: string;
}

const RichText: React.FC<RichTextProps> = ({ text, className }) => {
  const defaultClasses = 'text-sm text-left';

  return (
    <p className={className ? className : defaultClasses}>
      {text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </p>
  );
};

export default RichText;

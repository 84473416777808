import React from 'react';
import MultiSelectQuestion from './Question/Question.MultiSelect';
import SelectQuestion from './Question/Question.Select';
import ConcernsQuestion from './Question/Question.Concerns';
import Page from './Page/Page';
import { Component } from './types';

type QuizComponentMapperProps = Component;

const QuizComponentMapper: React.FC<QuizComponentMapperProps> = props => {
  switch (props.type) {
    case 'SELECT':
      return <SelectQuestion question={props} />;
    case 'MULTI_SELECT':
      return <MultiSelectQuestion question={props} />;
    case 'CONCERNS':
      return <ConcernsQuestion question={props} />;
    case 'PAGE':
      return <Page content={props} />;
    default:
      return null;
  }
};

export default QuizComponentMapper;

import React from 'react';
import logo from '@/media/logo.svg';
import google from '@/media/google.svg';

const Login: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-[rgba(239,239,187,1)] to-[rgba(212,211,221,1)]">
      <div
        className="border w-[500px] h-[670px] rounded-lg flex flex-col items-center p-6"
        style={{ backgroundColor: 'rgba(251,249,245,1)' }}
      >
        <img src={logo} className="mt-20" width="180" height="180" alt="Opaline Logo" />
        <p className="mt-20 text-xl text-center font-normal">
          Please sign in with your email
        </p>
        <input
          type="email"
          placeholder="Enter your email"
          className="mt-5 p-2 border border-gray-400 rounded-lg w-full max-w-80 text-center"
          required
        />
        <button
          className="mt-5 px-4 py-2 text-white rounded-lg w-80"
          style={{ backgroundColor: 'rgba(173, 108, 11, 1)' }}
        >
          Sign In
        </button>
        <div className="mt-20 w-36 border-t border-black"></div>
        <p className="mt-7 font-normal">Or sign in with:</p>
        <div className="mt-8 flex space-x-8">
          <img src={google} width="44" height="44" alt="Google Link" />
          <img src={google} width="44" height="44" alt="Google Link" />
          <img src={google} width="44" height="44" alt="Google Link" />
        </div>
      </div>
    </div>
  );
};

export default Login;

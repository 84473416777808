import React from 'react';
import './SpinnerLoader.css';
import loader from '@/media/favicon.svg';

interface SpinnerLoaderProps {
  fadeOut: boolean;
}

const SpinnerLoader: React.FC<SpinnerLoaderProps> = ({ fadeOut }) => {
  return (
    <div className={`spinner-loader ${fadeOut ? 'fade-out' : ''}`}>
      <img src={loader} alt="Loading..." className="spinner h-12" />
    </div>
  );
};

export default SpinnerLoader;

import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import RichText from '@/components/RichText/RichText';
import ConcernPills from '@/components/ConcernPills/ConcernPills';
import SpinnerLoader from '@/components/SpinnerLoader/SpinnerLoader';
import Line from '@/components/Line/Line';
import { useHeaderStore } from '@/stores/useHeaderStore';
import { useQuizStore } from '@/stores/useQuizStore';
import { getConcernsFromProducts } from '@/utils/concerns';
import {
  useGetProductGroupByIdQuery,
  useGetQuizResultQuery,
  Tag,
  ProductGroup,
} from '@/graphql/generated/graphql';
import Blurb from '@/components/Blurb/Blurb';
import { getProductImageForDemographic } from '@/utils/media';
import { getStartingPriceForProductGroup } from '@/utils/products';
import { useFooterStore } from '@/stores/useFooterStore';
import { useProviderStore } from '@/stores/useProviderStore';

const PGP: React.FC = () => {
  const navigate = useNavigate();
  const { productGroupId } = useParams<{ productGroupId: string }>();

  const {
    setLeftButton,
    setTitle,
    setTitleLevel,
    setRightButton,
    setOnLeftButtonClick,
    setOnRightButtonClick,
  } = useHeaderStore();
  const { setButtonText, setOnButtonClick, setOnSecondaryButtonClick } = useFooterStore();
  const { quizResultsId, answers } = useQuizStore();
  const isMale = answers?.gender[0] === 'Male' || false;
  const { settings } = useProviderStore();

  const { data, loading, error } = useGetProductGroupByIdQuery({
    variables: { id: productGroupId! },
  });

  const {
    data: quizResultData,
    loading: quizResultLoading,
    error: quizResultError,
  } = useGetQuizResultQuery({
    variables: { id: quizResultsId },
  });

  useEffect(() => {
    setLeftButton('Back to Results');
    setRightButton('Book Now');
    setOnLeftButtonClick(() => navigate('/my-results?onboarded=true'));
    setOnRightButtonClick(() => window.open(settings!.links['Book'], '_blank'));
    setTitleLevel(1);
    setButtonText('Ready to book?', 'Text us');
    setOnButtonClick(() => window.open(settings!.links['Book'], '_blank'));
    setOnSecondaryButtonClick(() => window.open(settings!.links['Contact'], '_blank'));
    if (!loading && !error) setTitle(data?.productGroups[0].name || '');
  }, [
    setLeftButton,
    setRightButton,
    setOnLeftButtonClick,
    setOnRightButtonClick,
    setTitle,
    loading,
    error,
    data,
    navigate,
    setButtonText,
    setOnButtonClick,
    setOnSecondaryButtonClick,
    setTitleLevel,
    settings,
  ]);

  if (loading || quizResultLoading)
    return (
      <SpinnerLoader
        fadeOut={!loading && !!data && !quizResultLoading && !!quizResultData}
      />
    );
  if (error || quizResultError)
    return (
      <p>Error: {error?.message || quizResultError?.message || 'Something went wrong'}</p>
    );
  const productGroup = data?.productGroups[0] as ProductGroup;
  const products = productGroup?.products || [];
  const concerns = products
    .map(product => getConcernsFromProducts(product) as Tag[])
    .flatMap(concerns => concerns);
  const selectedConcerns = quizResultData?.quizResults[0]?.concerns as Tag[];
  const image = getProductImageForDemographic(productGroup.media, isMale);
  const startingPrice = getStartingPriceForProductGroup(productGroup);

  return (
    <div className="flex flex-col justify-center items-center pb-6">
      <div className="px-4 pb-4">
        {image && (
          <div
            className="relative aspect-[16/9] w-full overflow-hidden bg-cover bg-center"
            style={{ backgroundImage: `url('${image}')` }}
          />
        )}
        {productGroup.description && (
          <>
            <Line />
            <p className="text-xl text-center font-normal mb-3">How it works</p>
            <RichText text={productGroup.description} className="text-base" />
          </>
        )}

        {concerns.length > 0 && (
          <>
            <Line />
            <p className="text-xl text-center font-normal mb-3">What it helps treat</p>
            <ConcernPills concerns={concerns} selectedConcerns={selectedConcerns} />
          </>
        )}

        {startingPrice && (
          <>
            <Line />
            <p className="pt-4 pb-2 font-normal text-center text-base">
              Pricing starts at ${startingPrice}
            </p>
          </>
        )}

        {products.length > 0 && (
          <>
            <Line />
            <p className="text-xl text-center font-normal mb-3">Products Carried</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {products.map(product => (
                // eslint-disable-next-line
                <div
                  key={product.id}
                  className="border border-gray-300 p-4 rounded-lg text-center hover:border-gray-500 hover:transition-all transition-all hover:ease-in ease-in"
                  onClick={() =>
                    navigate(`/my-results/pgp/${productGroupId}/pdp/${product.id}`)
                  }
                >
                  {product.name && (
                    <p className="font-semibold pt-4 pb-2">{product.name}</p>
                  )}
                  <Blurb
                    text={product.description || ''}
                    maxLength={65}
                    onClick={() =>
                      navigate(`/my-results/pgp/${productGroupId}/pdp/${product.id}`)
                    }
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PGP;

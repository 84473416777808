import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import RichText from '@/components/RichText/RichText';
import ConcernPills from '@/components/ConcernPills/ConcernPills';
import SpinnerLoader from '@/components/SpinnerLoader/SpinnerLoader';
import Line from '@/components/Line/Line';
import WhatToExpect from './WhatToExpect';
import { useHeaderStore } from '@/stores/useHeaderStore';
import { useQuizStore } from '@/stores/useQuizStore';
import { getConcernsFromProducts } from '@/utils/concerns';
import {
  Product,
  useGetProductByIdQuery,
  useGetQuizResultQuery,
  Tag,
} from '@/graphql/generated/graphql';
import { getProductImageForDemographic } from '@/utils/media';
import { getStartingPriceForProduct } from '@/utils/products';
import { useFooterStore } from '@/stores/useFooterStore';
import { useProviderStore } from '@/stores/useProviderStore';

const PDP: React.FC = () => {
  const navigate = useNavigate();
  const { productId } = useParams<{ productId: string }>();
  const {
    setLeftButton,
    setTitle,
    setTitleLevel,
    setRightButton,
    setOnLeftButtonClick,
    setOnRightButtonClick,
  } = useHeaderStore();
  const { quizResultsId, answers } = useQuizStore();
  const { setButtonText, setOnButtonClick, setOnSecondaryButtonClick } = useFooterStore();
  const isMale = answers?.gender[0] === 'Male' || false;
  const { settings } = useProviderStore();

  const { data, loading, error } = useGetProductByIdQuery({
    variables: { id: productId! },
  });
  const {
    data: quizResultData,
    loading: quizLoading,
    error: quizError,
  } = useGetQuizResultQuery({
    variables: { id: quizResultsId },
  });

  useEffect(() => {
    setLeftButton('Back to Results');
    setRightButton('Book Now');
    setOnLeftButtonClick(() => navigate('/my-results?onboarded=true'));
    setOnRightButtonClick(() => window.open(settings!.links['Book'], '_blank'));
    setTitleLevel(1);
    setButtonText('Ready to book?', 'Text us');
    setOnButtonClick(() => window.open(settings!.links['Book'], '_blank'));
    setOnSecondaryButtonClick(() => window.open(settings!.links['Contact'], '_blank'));
    if (!loading && !error) setTitle(data?.products[0].name || '');
  }, [
    setLeftButton,
    setRightButton,
    setOnLeftButtonClick,
    setOnRightButtonClick,
    setTitle,
    setTitleLevel,
    setButtonText,
    setOnButtonClick,
    setOnSecondaryButtonClick,
    data?.products,
    error,
    loading,
    navigate,
    settings,
  ]);

  if (loading || quizLoading)
    return (
      <SpinnerLoader fadeOut={!loading && !!data && !quizLoading && !!quizResultData} />
    );
  if (error || quizError)
    return <p>Error: {error?.message || quizError?.message || 'Something went wrong'}</p>;
  const product = data?.products[0] as Product;
  const concerns = getConcernsFromProducts(product) as Tag[];
  const selectedConcerns = quizResultData?.quizResults[0]?.concerns as Tag[];
  const image = getProductImageForDemographic(product.media, isMale);
  const startingPrice = getStartingPriceForProduct(product);
  const isUnitPrice =
    product.providerProduct && product.providerProduct[0]?.actualPrice === startingPrice;
  const priceBlurb = `Pricing starts at $${startingPrice}${isUnitPrice ? '/unit' : ''}`;

  return (
    <div className="flex flex-col justify-center items-center pb-6">
      <div className="px-4 pb-4">
        {image && (
          <div
            className="relative aspect-[16/9] w-full overflow-hidden bg-cover bg-center"
            style={{ backgroundImage: `url('${image}')` }}
          />
        )}
        {product.description && (
          <>
            <Line />
            <p className="text-xl text-center font-normal mb-3">How it works</p>
            <RichText text={product.description} className="text-base" />
          </>
        )}

        {concerns.length > 0 && (
          <>
            <Line />
            <p className="text-xl text-center font-normal mb-3">What it helps treat</p>
            <ConcernPills concerns={concerns} selectedConcerns={selectedConcerns} />
          </>
        )}

        {startingPrice && (
          <>
            <Line />
            <p className="pt-4 pb-2 font-normal text-center text-base">{priceBlurb}</p>
          </>
        )}
        <WhatToExpect product={product} />
      </div>
    </div>
  );
};

export default PDP;

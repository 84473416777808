import React from 'react';
import Pill from '@/components/Pill/Pill';
import { Tag } from '@/graphql/generated/graphql';
import { matchConcerns } from '@/utils/concerns';

interface ConcernPillsProps {
  concerns: Tag[];
  selectedConcerns: Tag[];
  onlySelectedConcerns?: boolean;
}

const ConcernPills: React.FC<ConcernPillsProps> = ({
  concerns,
  selectedConcerns,
  onlySelectedConcerns = false,
}) => {
  const matchedConcerns = matchConcerns(concerns, selectedConcerns);
  const uniqueConcerns = matchedConcerns.filter(concern =>
    onlySelectedConcerns ? concern.active : true
  );

  return (
    <div className="flex flex-wrap justify-center gap-1 mb-6">
      {uniqueConcerns.map(concern => (
        <Pill key={concern.id} text={concern.name} active={concern.active} />
      ))}
    </div>
  );
};

export default ConcernPills;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuizStore } from '@/stores/useQuizStore';
import { useHeaderStore } from '@/stores/useHeaderStore';
import { useFooterStore } from '@/stores/useFooterStore';
import Container from '@/components/Container/Container';
import QuizComponentMapper from './Quiz.ComponentMapper';
import { quiz } from './mocks/quiz';
import { FullStory } from '@fullstory/browser';

const Quiz: React.FC = () => {
  const navigate = useNavigate();
  const currentQuestionIndex = useQuizStore(state => state.currentQuestionIndex);
  const setCurrentQuestionIndex = useQuizStore(state => state.setCurrentQuestionIndex);
  const answers = useQuizStore(state => state.answers);
  const { setButtonText, setOnButtonClick, setDisableButton } = useFooterStore();
  const { setLeftButton, setOnLeftButtonClick, setTitle, setTitleLevel, setProgress } =
    useHeaderStore();

  const currentComponent = quiz.components[currentQuestionIndex];

  // Header Effects
  useEffect(() => {
    const previousComponent = () => {
      if (currentQuestionIndex > 0) {
        setCurrentQuestionIndex(currentQuestionIndex - 1);
      }
    };

    setProgress((currentQuestionIndex / (quiz.components.length + 1)) * 100); // +1 ensures photo step is included
    setTitleLevel(2);
    if (currentComponent.type !== 'PAGE') setTitle(currentComponent.text || '');
    if (currentComponent.type === 'PAGE') setTitle('');
    if (currentQuestionIndex > 0) {
      setLeftButton('Back');
      setOnLeftButtonClick(previousComponent);
    } else {
      setLeftButton('');
    }
  }, [
    currentComponent,
    currentQuestionIndex,
    setLeftButton,
    setOnLeftButtonClick,
    setTitle,
    setTitleLevel,
    setProgress,
    setCurrentQuestionIndex,
  ]);

  // Footer Effects
  useEffect(() => {
    const isCurrentQuestionAnswered = () => {
      const currentQuestionKey = currentComponent.componentKey;
      return (
        Array.isArray(answers[currentQuestionKey]) &&
        answers[currentQuestionKey]?.length > 0
      );
    };

    const nextComponent = () => {
      if (currentQuestionIndex < quiz.components.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
      if (currentQuestionIndex === quiz.components.length - 1) {
        navigate('/quiz/take-a-photo');
      }
    };

    let buttonText = '';
    if (currentComponent.type === 'PAGE' && currentComponent.buttonText) {
      // If the current component is a page, use the button text
      buttonText = currentComponent.buttonText;
    } else if (
      currentComponent.type !== 'PAGE' &&
      currentQuestionIndex < quiz.components.length - 1
    ) {
      // If the current component is a question, use the next question title
      buttonText = `Next: ${quiz.components[currentQuestionIndex + 1].title}`;
    } else {
      // If the current component is the last question, use the take a photo button text
      buttonText = 'Next: Take a photo';
    }

    setButtonText(buttonText);
    setOnButtonClick(() => nextComponent());
    setDisableButton(!isCurrentQuestionAnswered() && currentComponent.type !== 'PAGE');
  }, [
    navigate,
    setCurrentQuestionIndex,
    answers,
    currentQuestionIndex,
    currentComponent,
    setButtonText,
    setOnButtonClick,
    setDisableButton,
  ]);

  useEffect(() => {
    console.log(`Quiz Question ${currentQuestionIndex + 1}: ${currentComponent.text}`);
    FullStory('trackEvent', {
      name: `Quiz Question ${currentQuestionIndex}: ${currentComponent.text}`,
      properties: {},
    });
  }, [currentComponent, currentQuestionIndex]);

  return (
    <Container centerVertical={currentComponent.type === 'PAGE'}>
      <QuizComponentMapper {...currentComponent} />
    </Container>
  );
};

export default Quiz;

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FullStory } from '@fullstory/browser';
import Webcam from 'react-webcam';
import * as tf from '@tensorflow/tfjs';
import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';
import { usePhotoStore } from '@/stores/usePhotoStore';
import '@tensorflow/tfjs-backend-webgl';
import leftArrow from '@/media/left-arrow.white.svg';
import { useNavigate } from 'react-router-dom';
import Button from '@/components/Button/Button';

const debugMode = false;
const inputResolution = {
  width: 720, // Higher width for better resolution
  height: 960, // 4:3 aspect ratio for portrait images
};
const videoConstraints = {
  width: inputResolution.width,
  height: inputResolution.height,
  facingMode: 'user',
};
const screenThreshold = 0.05; // 0 to 1 representing %, eg. 0.8 is 80% of screen space
const lightingThreshold = 90; // 0 to 255 representing rgba black to white point

const TakePhoto: React.FC = () => {
  const navigate = useNavigate();
  const setPhoto = usePhotoStore(state => state.setPhoto);
  const setStep = usePhotoStore(state => state.setStep);
  const [isVideoLoaded, setVideoLoaded] = useState(false);
  const [isModelLoaded, setModelLoaded] = useState(false);
  const [isModelLoadError, setModelLoadError] = useState(false);
  const [isAligned, setIsAligned] = useState(false);
  const [isLightingGood, setIsLightingGood] = useState(false);
  const [isFaceSizeCorrect, setIsFaceSizeCorrect] = useState(false);
  const [isCameraAllowed, setIsCameraAllowed] = useState(true);
  const [isModelLoadingDelayed, setIsModelLoadingDelayed] = useState(false);

  const webcamRef = useRef<Webcam>(null);
  const modelRef = useRef<faceLandmarksDetection.FaceLandmarksDetector | null>(null);
  const animationFrameId = useRef<number | null>(null);
  const startModelLoadRef = useRef<number | null>(null);
  const endModelLoadRef = useRef<number | null>(null);

  const loadModel = useCallback(async () => {
    if (modelRef.current) return; // Prevent loading the model multiple times

    try {
      if (modelRef.current === null) {
        console.log('Loading face detection model...');
        startModelLoadRef.current = performance.now(); // Record start time
        await tf.setBackend('webgl'); // Use WebGL backend for better performance
        await tf.ready(); // Ensure TensorFlow is available before loading the model
        const model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
        const loadedModel = await faceLandmarksDetection.createDetector(model, {
          runtime: 'tfjs',
          refineLandmarks: true,
        });
        modelRef.current = loadedModel;
        setModelLoaded(true);
        endModelLoadRef.current = performance.now(); // Record end time
      }
    } catch (error) {
      setModelLoadError(true);
      console.error('Error loading face detection model:', error);
    }
  }, []);

  useEffect(() => {
    if (endModelLoadRef.current && startModelLoadRef.current) {
      const loadTime = endModelLoadRef.current - startModelLoadRef.current;

      // Tracking to understand how sustainable TFJS is for browser usage.
      FullStory('trackEvent', {
        name: 'TensforFlowJS Model',
        properties: {
          loadTime: loadTime,
          loadSuccess: isModelLoaded,
        },
      });
    }
  }, [isModelLoaded]);

  const analyzeBrightness = (video: HTMLVideoElement) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const frame = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const pixels = frame.data;
    let brightnessSum = 0;

    for (let i = 0; i < pixels.length; i += 4) {
      const r = pixels[i];
      const g = pixels[i + 1];
      const b = pixels[i + 2];
      const brightness = (r + g + b) / 3;
      brightnessSum += brightness;
    }

    const averageBrightness = brightnessSum / (pixels.length / 4);
    const isBrightEnough = averageBrightness > lightingThreshold; // Adjust the threshold as needed
    setIsLightingGood(isBrightEnough);
  };

  const detect = useCallback(async () => {
    if (!webcamRef.current || !webcamRef.current.video || !modelRef.current) return;
    const video = webcamRef.current.video as HTMLVideoElement;
    if (video.readyState !== 4) return;

    analyzeBrightness(video);

    const predictions = await modelRef.current.estimateFaces(video);

    if (predictions.length > 0) {
      const keypoints = predictions[0].keypoints;
      const leftEye = keypoints.find(point => point.name === 'leftEye');
      const rightEye = keypoints.find(point => point.name === 'rightEye');
      const lips = keypoints.find(point => point.name === 'lips');

      if (leftEye && rightEye && lips) {
        // Check if the face size is approximately 80% of the bounded screen
        const faceWidth = Math.abs(leftEye.x - rightEye.x);
        const faceHeight = Math.abs(lips.y - leftEye.y);
        const faceArea = faceWidth * faceHeight;
        const videoArea = video.videoWidth * video.videoHeight;

        const isCorrectSize = faceArea / videoArea >= screenThreshold; // Face should cover ~80% of the screen
        if (debugMode) console.log({ isCorrectSize, faceArea, videoArea });
        setIsFaceSizeCorrect(isCorrectSize);

        // Check if the face is centered within the oval
        const isLeftEyeInOval = isPointInOval(leftEye.x, leftEye.y);
        const isRightEyeInOval = isPointInOval(rightEye.x, rightEye.y);
        const isLipsInOval = isPointInOval(lips.x, lips.y);
        if (debugMode) console.log({ isLeftEyeInOval, isRightEyeInOval, isLipsInOval });
        setIsAligned(isLeftEyeInOval && isRightEyeInOval && isLipsInOval);
      } else {
        setIsAligned(false);
        setIsFaceSizeCorrect(false);
      }
    } else {
      setIsAligned(false);
      setIsFaceSizeCorrect(false);
    }

    animationFrameId.current = requestAnimationFrame(detect);
  }, [modelRef, webcamRef]);

  const isPointInOval = (x: number, y: number) => {
    const rx = (inputResolution.width * 0.8) / 2;
    const ry = (inputResolution.height * 0.8) / 2;
    const h = inputResolution.width / 2;
    const k = inputResolution.height / 2;
    return Math.pow((x - h) / rx, 2) + Math.pow((y - k) / ry, 2) <= 1;
  };

  const handleVideoLoad = useCallback(
    (videoNode: React.SyntheticEvent<HTMLVideoElement>) => {
      const video = videoNode.currentTarget;
      if (video.readyState !== 4) return;
      setVideoLoaded(true);
    },
    []
  );

  useEffect(() => {
    if (isVideoLoaded && isModelLoaded) detect();
  }, [isVideoLoaded, isModelLoaded, detect]);

  useEffect(() => {
    loadModel();
  }, [loadModel]);

  useEffect(() => {
    return () => {
      if (animationFrameId.current) cancelAnimationFrame(animationFrameId.current);
      if (modelRef.current) modelRef.current.dispose();
    };
  }, []);

  // Set timeout to show prompt if model takes longer than 5 seconds to load
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsModelLoadingDelayed(true);
      if (!isModelLoaded) console.error('TFJS model loading is taking too long.');
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [isModelLoaded]);

  const handleCapture = () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc && isAligned && isLightingGood && isFaceSizeCorrect) {
      setPhoto(imageSrc);
      setStep('PREVIEW_PHOTO');
    }
  };

  const handleUserMediaError = (error: string | DOMException) => {
    console.error(error);
    setIsCameraAllowed(false);

    if (error === 'NotAllowedError') console.error('Camera permission denied.');
  };

  if (!isCameraAllowed) {
    return (
      <div className="fixed top-0 bottom-0 left-0 right-0 h-full w-full flex flex-col justify-center items-center bg-black z-50 gap-4 p-8">
        <p className="text-white text-2xl">
          Please allow camera <br />
          access to continue.
        </p>
        <p className="text-white text-base">
          You may need to configure your device or browser settings to allow us to take
          your photo!
        </p>
        <Button shrink onClick={() => setStep('CHOOSE_OPTION')}>
          Go back
        </Button>
        <Button shrink onClick={() => navigate('/quiz/get-my-results')}>
          Skip this step
        </Button>
      </div>
    );
  }

  if (modelRef.current === null && !isModelLoadError) {
    return (
      <div className="fixed top-0 bottom-0 left-0 right-0 h-full w-full flex flex-col justify-center items-center bg-black z-50 gap-4 p-8">
        <p className="text-white text-2xl">Loading the camera...</p>
        <p className="text-white text-base">Please wait while we prepare the camera.</p>
        {isModelLoadingDelayed && (
          <div className="mt-4 flex flex-col gap-6">
            <p className="text-white text-lg">Taking too long?</p>
            <Button shrink onClick={() => setStep('CHOOSE_OPTION')}>
              Go back
            </Button>
            <Button shrink onClick={() => navigate('/quiz/get-my-results')}>
              Skip this step
            </Button>
          </div>
        )}
      </div>
    );
  }

  const renderCameraPrompts = () =>
    (!isAligned || !isLightingGood || !isFaceSizeCorrect) && (
      <div className="absolute inset-0 flex flex-col justify-center items-center space-y-2">
        {!isLightingGood && <p className="text-white text-lg">Insufficient lighting</p>}
        {!isAligned && <p className="text-white text-lg">Please center your face</p>}
        {!isFaceSizeCorrect && (
          <p className="text-white text-lg">Move closer to the camera</p>
        )}
      </div>
    );

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 h-full w-full flex flex-col justify-center items-center bg-black z-50">
      <div className="relative w-full h-full flex justify-center items-center">
        <Webcam
          height={inputResolution.height}
          width={inputResolution.width}
          audio={false}
          videoConstraints={videoConstraints}
          ref={webcamRef}
          onLoadedData={handleVideoLoad}
          screenshotFormat="image/jpeg"
          screenshotQuality={1} // Maximum quality for screenshots
          onUserMediaError={handleUserMediaError}
          className="fixed top-0 bottom-0 left-0 right-0 w-full h-full object-cover" // Ensure full coverage
          mirrored
        />
        {modelRef.current && (
          <>
            <div
              className="absolute"
              style={{
                border: '2px solid rgba(255, 255, 255, 0.8)',
                width: '80%', // 80% width for oval
                height: '80%', // 80% height for oval
                top: '10%', // Center the oval in the middle of the webcam feed
                left: '10%',
                borderRadius: '50%',
                backgroundColor: 'rgba(0, 0, 0, 0.2)', // Translucent background
                pointerEvents: 'none',
              }}
            ></div>
            {renderCameraPrompts()}
          </>
        )}
      </div>
      <div className="w-full p-4 flex justify-center items-center bg-black z-10">
        <button
          className="flex items-center text-gray-300 w-16 pr-4"
          onClick={() => setStep('CHOOSE_OPTION')}
        >
          <img
            src={leftArrow}
            className="mr-1.5"
            alt="Left Arrow"
            width="10"
            height="10"
          />
          Back
        </button>
        <button
          onClick={handleCapture}
          disabled={
            modelRef.current !== null &&
            (!isAligned || !isLightingGood || !isFaceSizeCorrect)
          }
          className={`bg-white rounded-full p-4 shadow-lg ${
            !isAligned || !isLightingGood || !isFaceSizeCorrect || isModelLoadError
              ? 'opacity-50 cursor-not-allowed'
              : ''
          }`}
        >
          <div className="w-12 h-12 border-4 border-black rounded-full"></div>
        </button>
        <div className="w-16" />
      </div>
    </div>
  );
};

export default TakePhoto;

import React from 'react';

interface PillProps {
  text: string;
  active?: boolean;
  className?: string;
  onClick?: () => void;
}

const Pill: React.FC<PillProps> = ({ text, className, active, onClick }) => {
  const defaultClasses = 'border border-black rounded-full px-4 py-1 text-xs';
  let classes = className ? className : defaultClasses;
  if (active) classes += ' bg-black text-white';

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <span className={classes} onClick={onClick}>
      {text}
      {!!onClick && <span className="pl-1 left-0.5 relative">x</span>}
    </span>
  );
};

export default Pill;

import React, { useRef } from 'react';

type LabelAlign = 'center' | 'left' | 'right';
type InputType = 'TEXT' | 'EMAIL' | 'PHONE' | 'CODE';

interface InputProps {
  inputName?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  withLabel?: boolean;
  labelText?: string;
  labelAlign?: LabelAlign;
  autoComplete?: string;
  inputType?: InputType;
  inputPlaceholder?: string;
  maxLength?: number;
  noShrink?: boolean;
  autoFocus?: boolean;
  handleOnInput?: React.FormEventHandler<HTMLInputElement> | undefined;
  handleOnKeyDown?: React.FormEventHandler<HTMLInputElement> | undefined;
  handleOnFocus?: React.FormEventHandler<HTMLInputElement> | undefined;
  inputId?: string;
}

const defaultProps = {
  TEXT: {
    type: 'text',
    textClass: 'py-3 text-center font-assistant text-lg',
  },
  EMAIL: {
    type: 'email',
    placeholder: 'Enter your email address',
    textClass: 'py-3 text-center font-assistant text-lg',
  },
  PHONE: {
    type: 'tel',
    textClass: 'py-3 text-center font-assistant text-lg',
  },
  CODE: {
    type: 'tel',
    //    type: 'text',
    placeholder: '----',
    textClass:
      'pt-0 pb-1 text-center font-assistant text-5xl font-extrabold tracking-widest caret-transparent',
    maxLength: 4,
  },
} as {
  [index: string]: {
    [index: string]: string | number;
  };
};

const Input: React.FC<InputProps> = ({
  inputName,
  inputRef,
  withLabel,
  labelText,
  labelAlign,
  autoComplete,
  inputType,
  inputPlaceholder,
  maxLength,
  noShrink,
  autoFocus,
  handleOnInput,
  handleOnKeyDown,
  handleOnFocus,
  inputId,
}) => {
  const newRef = useRef<HTMLInputElement>(null);
  const thisRef = inputRef || newRef;

  const randomInputId = `input-${Math.floor(1000000 + Math.random() * 9000000)}`;

  const defaultProp = (setting: string) => defaultProps[inputType || 'TEXT'][setting];
  const htmlType = defaultProp('type');
  let textClass = defaultProp('textClass') as string;

  const handleOnInputWrapped = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (inputType === 'CODE') {
      event.currentTarget.value = event.currentTarget.value
        .toUpperCase()
        .replace(/[^0-9]/, '');
    }
    if (handleOnInput) handleOnInput(event);
  };

  let defaultTextPlaceholder;
  if (htmlType === 'text' && labelText && !inputPlaceholder) {
    const fieldText = labelText.toLowerCase().match(/^[a-z0-9 ]+/);
    defaultTextPlaceholder = `Please enter your ${fieldText}`;
  }

  if (!noShrink && thisRef.current && thisRef.current.value.length > 15)
    textClass = textClass.replace(/text-(lg|md)/, 'text-sm');

  return (
    <>
      {withLabel && (
        <label
          htmlFor={inputId || randomInputId}
          className={`block text-${labelAlign || 'center'} mb-2`}
        >
          {labelText || defaultProp('labelText')}
        </label>
      )}
      {/* Assume use of this control has been reviewed for accessibility issues */}
      {/* autofocus has value in at least initial signup where potential drop-off is a major concern */}
      {/* eslint-disable jsx-a11y/no-autofocus */}
      <input
        name={inputName}
        ref={thisRef}
        id={inputId || randomInputId}
        type={htmlType as string}
        placeholder={
          inputPlaceholder ||
          defaultTextPlaceholder ||
          (defaultProp('placeholder') as string)
        }
        className={`w-60 px-2 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500 ${textClass}`}
        maxLength={maxLength || (defaultProp('maxLength') as number)}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        onInput={handleOnInputWrapped}
        onKeyDown={handleOnKeyDown}
        onFocus={handleOnFocus}
        spellCheck="false"
      />
    </>
  );
};

export default Input;

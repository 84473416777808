import { create } from 'zustand';

interface PhotoState {
  currentStep: 'CHOOSE_OPTION' | 'TAKE_PHOTO' | 'PREVIEW_PHOTO';
  photo: string | null;
  setStep: (step: 'CHOOSE_OPTION' | 'TAKE_PHOTO' | 'PREVIEW_PHOTO') => void;
  setPhoto: (photo: string) => void;
  resetPhoto: () => void;
}

export const usePhotoStore = create<PhotoState>(set => ({
  currentStep: 'CHOOSE_OPTION',
  photo: null,
  setStep: step => set({ currentStep: step }),
  setPhoto: photo => set({ photo }),
  resetPhoto: () => set({ photo: null, currentStep: 'CHOOSE_OPTION' }),
}));

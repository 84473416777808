import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ApolloProvider from '@/graphql/ApolloProvider';
import AuthProvider from '@/auth/AuthProvider';
import * as Sentry from '@sentry/react';
import { init as initFullStory } from '@fullstory/browser';

const getEnvironment = () => {
  const hostname = window.location.hostname;
  const isStage = hostname.indexOf('stage.opaline.ai') !== -1;
  const isProd = hostname.indexOf('opaline.ai') !== -1;
  return isStage ? 'staging' : isProd ? 'production' : 'local';
};

initFullStory({
  orgId: 'o-21WV8M-na1',
  devMode: getEnvironment() === 'local',
});

Sentry.init({
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: getEnvironment(),
  dsn: 'https://3b443c872171c0e568fb573687cd4880@o4508170214178816.ingest.us.sentry.io/4508170215751680',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    // /^https:\/\/opaline\.ai/,
    // /^https:\/\/.*\.opaline\.ai/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  profilesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ApolloProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

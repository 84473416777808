import { useEffect } from 'react';
import { Question } from '../types';
import { useQuizStore } from '@/stores/useQuizStore';

export const useQuestion = (initialQuestion: Question) => {
  const answers = useQuizStore(state => state.answers);
  const setAnswer = useQuizStore(state => state.setAnswer);

  useEffect(() => {
    // Initialize selected answers with Zustand state
    if (!answers[initialQuestion.componentKey]) {
      setAnswer(initialQuestion.componentKey, []);
    }
  }, [initialQuestion.componentKey, answers, setAnswer]);

  const handleAnswerChange = (updatedAnswers: string[]) => {
    setAnswer(initialQuestion.componentKey, updatedAnswers);
  };

  return {
    selectedAnswers: answers[initialQuestion.componentKey] || [],
    handleAnswerChange,
  };
};

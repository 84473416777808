import {
  Tag,
  Product,
  ProductGroup,
  QuizResultProduct,
} from '@/graphql/generated/graphql';

export const getUniqueProductGroups = (products: Product[]) => {
  const productGroupsMap = new Map();

  products.forEach(product => {
    product.productGroups.forEach(productGroup => {
      if (!productGroupsMap.has(productGroup.id)) {
        // If the product group id hasn't been added yet, add it to the map
        productGroupsMap.set(productGroup.id, {
          id: productGroup.id,
          name: productGroup.name,
          description: productGroup.description,
        });
      }
    });
  });

  // Convert the Map to an array of unique product groups
  return Array.from(productGroupsMap.values());
};

export const getUniqueProductGroupsWithTags = (products: Product[]) => {
  const productGroupsMap = new Map();

  products.forEach(product => {
    product.productGroups.forEach(productGroup => {
      if (!productGroupsMap.has(productGroup.id)) {
        // If the product group hasn't been added yet, initialize it in the map
        productGroupsMap.set(productGroup.id, {
          id: productGroup.id,
          name: productGroup.name,
          description: productGroup.description,
          tags: new Map(), // We will use a Map to ensure unique tags by tag.id
        });
      }

      // Add the product's tags to the product group
      product.tags.forEach(tag => {
        const productGroupData = productGroupsMap.get(productGroup.id);
        if (!productGroupData.tags.has(tag.id)) {
          productGroupData.tags.set(tag.id, {
            id: tag.id,
            name: tag.name,
            type: tag.type,
          });
        }
      });
    });
  });

  // Convert the Map to an array of unique product groups and their tags
  return Array.from(productGroupsMap.values())
    .map(productGroup => ({
      ...productGroup,
      tags: Array.from(productGroup.tags.values()), // Convert tags Map back to an array
    }))
    .reverse();
};

export interface UniqueQuizProductGroup {
  id: string;
  name: string;
  description: string;
  rank: number;
  type: string;
  products: Product[];
  tags: Tag[];
}

export const getUniqueQuizProductGroups = (
  products: QuizResultProduct[]
): UniqueQuizProductGroup[] => {
  // Ensure products ranked highest to lowest.
  const quizProducts = sortProductsByRank(products);

  // Build a map of product groups with unique tags
  const productGroupsMap = new Map();

  quizProducts.forEach(quizProduct => {
    quizProduct.product.productGroups.forEach(productGroup => {
      if (!productGroupsMap.has(productGroup.id)) {
        // If the product group hasn't been added yet, initialize it in the map
        productGroupsMap.set(productGroup.id, {
          id: productGroup.id,
          name: productGroup.name,
          description: productGroup.description,
          rank: quizProduct.rank,
          type: quizProduct.type,
          products: productGroup.products,
          tags: new Map(), // We will use a Map to ensure unique tags by tag.id
        });
      }

      // Add the product's tags to the product group
      quizProduct.product.tags.forEach(tag => {
        const productGroupData = productGroupsMap.get(productGroup.id);
        if (!productGroupData.tags.has(tag.id)) {
          productGroupData.tags.set(tag.id, {
            id: tag.id,
            name: tag.name,
            type: tag.type,
          });
        }
      });
    });
  });

  // Convert the Map to an array of unique product groups and their tags
  const result = Array.from(productGroupsMap.values())
    .map(productGroup => ({
      ...productGroup,
      tags: Array.from(productGroup.tags.values()), // Convert tags Map back to an array
    }))
    .sort((a, b) => {
      if (b.rank === a.rank) {
        return a.name.localeCompare(b.name); // Sort by name in alphabetical order if ranks are equal
      }
      return b.rank - a.rank; // Sort by rank in descending order
    });

  return result;
};

export const sortProductsByRank = (products: QuizResultProduct[]) =>
  products
    // Ensure we are working with a copy of the array
    .map(product => product)
    .sort((a, b) => {
      if (b.rank === a.rank) {
        // Sort by name in alphabetical order if ranks are equal
        return a.product.name.localeCompare(b.product.name);
      }
      // Sort by rank in descending order
      return b.rank - a.rank;
    });

export const getStartingPriceForProduct = (product: Product): number | null => {
  // If there is no providerProduct, return null
  if (!product.providerProduct) return null;

  // Extract all prices from providerProduct
  const prices = product.providerProduct
    .map(providerProduct => [
      providerProduct.unitPrice,
      providerProduct.actualPrice,
      providerProduct.minimumPrice,
      providerProduct.maximumPrice,
    ])
    .flatMap(price => price);

  // Filter out undefined or null prices
  // If there are no valid prices, return null
  const validPrices = prices.filter(price => price !== undefined && price !== null);
  if (validPrices.length === 0) return null;

  // Find the minimum price and return it
  const lowestPrice = Math.min(...validPrices);
  return lowestPrice;
};

export const getStartingPriceForProductGroup = (
  productGroup: ProductGroup
): number | null => {
  // Get the lowest price for each product in the product group
  const productPrices = productGroup.products
    .map(product => getStartingPriceForProduct(product))
    .filter(price => price !== null) as number[];

  // If there are no valid prices, return null
  if (productPrices.length === 0) return null;

  // Find the minimum price among all products
  const lowestPrice = Math.min(...productPrices);
  return lowestPrice;
};
